import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Creditstab from './Creditstab';

function Credits() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Credits" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Credits" title="Credits" className='p-4'>
                                        <Creditstab />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Credits