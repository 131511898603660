import React, { useState } from 'react';

function EmailTab() {
    const [filterInpVal, setFilterInpVal] = useState({
        payment_id: '',
        status: '',
        email: '',
        phone_no: '',
        notes: '',
        bank_ref_no: '',
        count: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>

            <div className="row mt-4 mb-2">
                <div className="col-lg-12">
                    {/* {loading && <Loadar />} */}
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">

                                <form>

                                    <div className='row p-4'>
                                        <h4 className='mb-4'>Email Notification</h4>
                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Enter email addresses that will receive email notifications regarding payments,settlements, daily payment reports, webhooks, etc.</label>
                                                <input type="email" className="form-control loginCustom-inp" id="usr" placeholder='abc@gmail.com' name='email' value={filterInpVal?.email} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4 pt-2 pb-2 ps-4 pe-4" type="button" onClick={handleSubmitFilterData}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default EmailTab