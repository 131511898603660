import React from 'react'
import Payments from './Payments';
import { Tabs, Tab } from 'react-bootstrap';
import Refunds from './Refunds';
import BatchRefunds from './BatchRefunds';
import Orders from './Orders';
import Disputes from './Disputes';

function TransactionComp() {
    
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Payments" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Payments" title="Payments" className='p-4'>
                                        <Payments />
                                    </Tab>
                                    <Tab eventKey="Refunds" title="Refunds" className='p-4'>
                                        <Refunds />
                                    </Tab>
                                    <Tab eventKey="Batch Refunds" title="Batch Refunds" className='p-4'>
                                        <BatchRefunds />
                                    </Tab>
                                    <Tab eventKey="Orders" title="Orders" className='p-4'>
                                        <Orders />
                                    </Tab>
                                    <Tab eventKey="Disputes" title="Disputes" className='p-4'>
                                        <Disputes />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionComp