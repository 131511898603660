import React from 'react'
import SubscriptionsComp from '../../components/subscriptionsComp/SubscriptionsComp'

function SubscriptionsPage() {
    return (
        <>
            <SubscriptionsComp />
        </>
    )
}

export default SubscriptionsPage