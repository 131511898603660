import React from 'react'
import LoginComp from '../../components/loginComp/LoginComp'

function LoginPage() {
    return (
        <>
            <LoginComp />
        </>
    )
}

export default LoginPage