import React from 'react'
import { Link } from 'react-router-dom'

function TransactionsLimitTab() {
    return (
        <div className='p-3'>
            <div className="row">
                <h4 className='m-2'>Transaction Limits</h4>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">

                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <th><span>Limit per domestic transaction:</span></th>
                                                    <td><b>50,000.00</b><Link to={"#"} className='text-primary' style={{ fontWeight: '600',marginLeft:'10px' }}>Edit</Link></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <th><span>Limit per international transaction:</span></th>
                                                    <td><Link to={"#"} className='text-primary' style={{ fontWeight: '600' }}>Apply For International</Link></td>
                                                </tr>
                                               

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TransactionsLimitTab