import React, { useState } from 'react';

function Brandingtab() {
    const [filterInpVal, setFilterInpVal] = useState({
        theme_color: '',
        default_language: '',
        collect_email: '',
        logo: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">
                <h3>Account Settings</h3>
                <div className="col-lg-12">
                    {/* {loading && <Loadar />} */}
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>


                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Theme Color</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Enter Theme Color' name='theme_color' value={filterInpVal?.theme_color} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Select Default Language</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='default_language' value={filterInpVal?.default_language} onChange={handleFilterChange}>
                                                    <option selected>Select default language</option>
                                                    <option value={'English'}>English</option>
                                                    <option value={'Hindi'}>Hindi</option>
                                                    <option value={'Urdu'}>Urdu</option>
                                                    <option value={'Arabic'}>Arabic</option>
                                                    <option value={'Bengali'}>Bengali</option>
                                                    <option value={'Marathi'}>Marathi</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Collect email address from users on Checkout page</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='collect_email' value={filterInpVal?.collect_email} onChange={handleFilterChange}>
                                                    <option selected>Select</option>
                                                    <option value={'Default'}>No (Default)</option>
                                                    <option value={'As An Optional Field'}>As An Optional Field</option>
                                                    <option value={'As A Mandatory Field'}>As A Mandatory Field</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Your Logo</label>
                                                <input type="file" className="form-control loginCustom-inp" id="usr" placeholder='' name='logo' value={filterInpVal?.logo} onChange={handleFilterChange} />
                                            </div>
                                        </div>



                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}> Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Brandingtab