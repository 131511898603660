import React from 'react'
import DevelopersComp from '../../components/developersComp/DevelopersComp'

function DevelopersPage() {
    return (
        <>
            <DevelopersComp />
        </>
    )
}

export default DevelopersPage