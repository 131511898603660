import React from 'react'
import PaymentLinksComp from '../../components/paymentLinksComp/PaymentLinksComp'

function PaymentLinksPage() {
    return (
        <>
            <PaymentLinksComp />
        </>
    )
}

export default PaymentLinksPage