import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function ViewPastCoupnsModal({ show7, handleClose7 }) {
    return (
        <div>
            <Modal
                show={show7}
                onHide={handleClose7}
                dialogClassName="modal-90w"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Past Coupons
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-3">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1">
                                        <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                            <div className='userTablewrapper'>
                                                <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                                    <thead>
                                                        <tr role="row">
                                                            <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Coupon Code</th>
                                                            <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Name</th>
                                                            <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Description</th>
                                                            <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Email Address: activate to sort column ascending" style={{ width: '140.45px' }}>Created At</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" className="odd">
                                                            <td><span>ABCD123</span></td>
                                                            <td><span>DDD Name</span></td>
                                                            <td><span>Coupon Desc</span></td>
                                                            <td><span>20/06/2024 11:00 hrs</span></td>
                                                        </tr>

                                                        <tr role="row" className="odd">
                                                            <td><span>ABCD123</span></td>
                                                            <td><span>DDD Name</span></td>
                                                            <td><span>Coupon Desc</span></td>
                                                            <td><span>20/06/2024 11:00 hrs</span></td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td><span>ABCD123</span></td>
                                                            <td><span>DDD Name</span></td>
                                                            <td><span>Coupon Desc</span></td>
                                                            <td><span>20/06/2024 11:00 hrs</span></td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td><span>ABCD123</span></td>
                                                            <td><span>DDD Name</span></td>
                                                            <td><span>Coupon Desc</span></td>
                                                            <td><span>20/06/2024 11:00 hrs</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose7}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default ViewPastCoupnsModal