import React from 'react'
import Settlements from '../../components/settlements/Settlements'

function SettlementsPage() {
    return (
        <>
            <Settlements />
        </>
    )
}

export default SettlementsPage