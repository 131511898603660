import React from 'react';
import { Link } from 'react-router-dom';
import { CgMoreR } from "react-icons/cg";
import { SiFoursquarecityguide } from "react-icons/si";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

function CaptureAndRefundSettingTab() {
    return (
        <>
            <div className="row mt-2 mb-2">

                <div className='mb-5'>
                    <div className="col-lg-12">
                        <div className='row p-2'>
                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <h4 className='mt-0 mb-0 me-4'>Payment Capture </h4>
                                <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                    <CgMoreR /> Know More
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <h5 style={{ fontSize: '20px', margin: '0' }}>AUTOMATIC CAPTURE <IoCheckmarkDoneCircleSharp /></h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '30px', alignItems: 'center' }}>
                                <p className='m-0'>Payments will be captured automatically if authorised by bank within <b>12 Mins</b></p>
                                <button className='btn btn-primary' style={{}}>Change</button>
                            </div>

                            <div className='mt-5'>
                                <p className='m-0'>Auto capture timeout <b>12 Mins</b></p>
                                <p className='m-0'>Normal refund after <b>12 Mins</b></p>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card">
                        <h5 style={{ textAlign: 'center', margin: '20px', color: '#2f8c98' }}> Capture settings are applicable only if  <Link to={"#"} className='text-primary'>Orders API</Link>  is used to create the payment. Capture values passed in the Orders API will override these settings if there is any conflict.</h5>
                        <div className='row p-2'>
                            <div className="col-lg-12">
                                <div className='row p-2'>
                                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                        <h4 className='mt-0 mb-0 me-4'>Default Refund Speed </h4>
                                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                            <SiFoursquarecityguide /> Api Reference Guide
                                        </Link>
                                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                            <CgMoreR /> Know More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>Normal Refund </h5>
                                        <div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
                                            </div>
                                        </div>
                                    </div>
                                    <p>Your customer will get refunds in 5-7 days.</p>
                                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '30px' }}>
                                        <Link to={"#"} className='text-secondary' style={{ padding: '5px 10px', backgroundColor: "#40b0aa6b" }}>Normal Speed</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>Instant Refund </h5>
                                        <div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                            </div>
                                        </div>
                                    </div>
                                    <p>At a <Link to={"#"} className='text-primary'>minimal fee</Link>, your customer will get refunds instantly.</p>
                                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '30px' }}>
                                        <Link to={"#"} className='text-secondary' style={{ padding: '5px 10px', backgroundColor: "#40b0aa6b" }}>Optimum Speed</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CaptureAndRefundSettingTab