import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PaymentLinksTab from './PaymentLinksTab';

function PaymentLinksComp() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading-detail'>
                                    <div className='row p-3'>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>1. Create Payment Link</h3>
                                            <p style={{ textAlign: 'center' }}>Create a payment link instantly and notify your customer via sms or email.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>2. Receive Payments</h3>
                                            <p style={{ textAlign: 'center' }}>Your customers can make domestic and international payments directly on the payment link..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Payment Links" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Payment Links" title="Payment Links" className='p-4'>
                                        <PaymentLinksTab />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PaymentLinksComp