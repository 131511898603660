import React from 'react'
import InvoicesComp from '../../components/invoicesComp/InvoicesComp'

function InvoicesPage() {
    return (
        <>
            <InvoicesComp />
        </>
    )
}

export default InvoicesPage