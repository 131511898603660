import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { SiAmazondocumentdb } from "react-icons/si";
import { MdCategory } from "react-icons/md";
import AddNewProduct from './AddNewProduct';
import AddListCategoryTab from './AddListCategoryTab';

function AddNewProductTab() {
    const [show, setShow] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleCloseCategoryModal = () => setShowCategoryModal(false);

    const [filterInpVal, setFilterInpVal] = useState({
        status: '',
        product_name: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    const openAddProductModal = () => {
        setShow(true)
    };
    const openAddListCategoryModal = () => {
        setShowCategoryModal(true)
    };

    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <LiaHandsHelpingSolid /> Need Help? Take a Tour
                            </Link>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }} onClick={openAddListCategoryModal}>
                                <MdCategory /> Manage Categories
                            </Link>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={openAddProductModal}>+ Add New Product</button>
                        </div>
                    </div>
                </div>


                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>

                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Status</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Status</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Active'}>Active</option>
                                                    <option value={'InActive'}>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Product Name</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Product Name' name='product_name' value={filterInpVal?.product_name} onChange={handleFilterChange} />
                                            </div>
                                        </div>


                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}><i className="fas fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <h4 style={{ textAlign: 'center', marginTop: '100px' }}>There are no products yet!!
                        Start creating products now.</h4>
                </div>
            </div>


            <AddNewProduct show={show} handleClose={handleClose} />
            <AddListCategoryTab showCategoryModal={showCategoryModal} handleCloseCategoryModal={handleCloseCategoryModal} />


        </>
    )
}

export default AddNewProductTab