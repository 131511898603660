import React from 'react';
import { Link } from 'react-router-dom';

function FeeBearerTab() {
    return (
        <>
            <div className="row mt-2 mb-2">
                <div className="col-lg-12">
                    <div className="card">
                        <h5 style={{ textAlign: 'center', margin: '20px', color: '#2f8c98' }}>For every payment done on AbarisPay, we levy a nominal platform fee. Choose your preferred mode of payment from the below options -</h5>
                        <div className='row p-2'>
                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>You pay the fee </h5>
                                        <div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
                                            </div>
                                        </div>
                                    </div>
                                    <p>AbarisPay platform fee would be borne by you.</p>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>Customer pays the fee</h5>
                                        <button disabled className="btn btn-primary custom-inp-btn rounded me-4" type="button" >Not Supported</button>
                                    </div>
                                    <p>This feature is not supported for merchants using QR, Smart Collect, Route and Magic Checkout. If you still wish to enable this model, please raise a <Link to={"#"} className='text-primary'>Support Ticket</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FeeBearerTab