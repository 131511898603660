import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import SubscriptionTabComp from './SubscriptionTabComp';
import PlanTabComp from './PlanTabComp';
import SubscriptionSettingComp from './SubscriptionSettingComp';

function SubscriptionsComp() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading-detail'>
                                    <div className='row p-3'>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>1. Create Plan</h3>
                                            <p style={{ textAlign: 'center' }}>Create your custom plans with different billing cycles and prices for your business.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>2. Create Subscription</h3>
                                            <p style={{ textAlign: 'center' }}>Create subscriptions for your customers to receive recurring payments</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>3. Receive Payments</h3>
                                            <p style={{ textAlign: 'center' }}>Share subscription link with your customers to receive recurring payments.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Subscriptions" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Subscriptions" title="Subscriptions" className='p-4'>
                                        <SubscriptionTabComp />
                                    </Tab>
                                    <Tab eventKey="Plans" title="Plans" className='p-4'>
                                        <PlanTabComp />
                                    </Tab>
                                    <Tab eventKey="Settings" title="Settings" className='p-4'>
                                        <SubscriptionSettingComp />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SubscriptionsComp