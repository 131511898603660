import React from 'react'
import ReportsTab from '../../components/reportsComp/ReportsTab'

function ReportsPage() {
    return (
        <>
            <ReportsTab />
        </>
    )
}

export default ReportsPage