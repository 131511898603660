import React from 'react';
import { FaCreditCard } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { MdOutlineErrorOutline } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdOutlineBroadcastOnHome } from "react-icons/md";
import { GrDocumentTransfer } from "react-icons/gr";

function SubscriptionSettingComp() {
    return (
        <>
            <div className="container">
                <div className="active-projects style-1">
                    <form>
                        <div className='row'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 className='me-3'>Payment Methods</h3>
                                <Link to={'#'} className='text-primary'>Know More <GrDocumentTransfer /></Link>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="row mt-5 mb-2">
                    <div className="card">
                        <div className="card-body p-2">
                            <div className='row'>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><RiSecurePaymentFill /></h3>
                                            <h3 >
                                                UPI
                                            </h3>
                                        </div>
                                        <p className="customcard-text m-0">Accept recurring payments via UPI apps like PhonePe, Paytm & BHIM for your subscriptions.</p>
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Only supports Indian currency.
                                        </Link>

                                        <div className='p-3' style={{ backgroundColor: "aliceblue", boxShadow: '0 0 5px lightblue', borderRadius: '5px', margin: '15px', fontSize: '16px' }}>
                                            <MdOutlineErrorOutline className='text-primary mb-1' /> Accept payments upto ₹ <b>1,00,000</b> (For BFSI: ₹ <b>2,00,000</b>) Payments above ₹ <b>15,000</b> will ask the customer for UPI PIN verification as well.
                                        </div>

                                        <div className='p-3' style={{ backgroundColor: "ghostwhite", boxShadow: '0 0 5px lightgray', borderRadius: '5px', margin: '15px', fontSize: '16px' }}>
                                            <p style={{ textAlign: 'center', fontSize: '12px' }}><MdOutlineErrorOutline className='text-primary' /> To enable this subscription, write an email to us at <Link to={'#'} className='text-primary'>subscription@abarispay.com</Link>
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdOutlineBroadcastOnHome /></h3>
                                            <h3 >
                                                eMandate
                                            </h3>
                                        </div>
                                        <p className="customcard-text m-0">Accept recurring payments directly via bank accounts for your subscriptions.</p>
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Only supports Indian currency.
                                        </Link>

                                        <div className='p-3' style={{ backgroundColor: "aliceblue", boxShadow: '0 0 5px lightblue', borderRadius: '5px', margin: '15px', fontSize: '16px' }}>
                                            <MdOutlineErrorOutline className='text-primary mb-1' /> Accept payments upto ₹ <b>1,00,00,000</b>
                                        </div>

                                        <div className='p-3' style={{ backgroundColor: "ghostwhite", boxShadow: '0 0 5px lightgray', borderRadius: '5px', margin: '15px', fontSize: '16px' }}>
                                            <p style={{ textAlign: 'center', fontSize: '12px' }}><MdOutlineErrorOutline className='text-primary' /> To enable this subscription, write an email to us at <Link to={'#'} className='text-primary'>subscription@abarispay.com</Link>
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><FaCreditCard /></h3>
                                            <h3 >
                                                Card
                                            </h3>
                                        </div>
                                        <p className="customcard-text m-0">Accept recurring payments via debit & credit cards for your subscriptions in any of our</p>
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Supported International Currencies
                                        </Link>

                                        <div className='p-3' style={{ backgroundColor: "aliceblue", boxShadow: '0 0 5px lightblue', borderRadius: '5px', margin: '15px', fontSize: '16px' }}>
                                            <MdOutlineErrorOutline className='text-primary mb-1' /> Accept payments upto ₹ <b>2,00,000</b> Payments above ₹ <b>15,000</b> will ask the customer for OTP verification as well.
                                        </div>

                                        <div>
                                            <p style={{ textAlign: 'center', fontSize: '14px' }}><strong>NOTE: </strong> Only limited cards are supported due to new payment regulations by RBI. <Link to={'#'} className='text-primary'>View supported cards</Link>
                                            </p>

                                        </div>

                                        <div className='p-3' style={{ backgroundColor: "ghostwhite", boxShadow: '0 0 5px lightgray', borderRadius: '5px', margin: '15px', fontSize: '16px' }}>
                                            <p style={{ textAlign: 'center', fontSize: '12px' }}><MdOutlineErrorOutline className='text-primary' /> To enable this subscription, write an email to us at <Link to={'#'} className='text-primary'>subscription@abarispay.com</Link>
                                            </p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionSettingComp