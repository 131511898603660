import React from 'react'
import AbarisPayMeLinkComp from '../../components/abarisPayMeLinkComp/AbarisPayMeLinkComp'

function AbarisPayMeLinkPage() {
    return (
        <>
            <AbarisPayMeLinkComp />
        </>
    )
}

export default AbarisPayMeLinkPage