import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import RpoutesPaymentsTab from './RpoutesPaymentsTab';
import TransfersTab from './TransfersTab';
import RevealsTab from './RevealsTab';
import RoutesAccountsTab from './RoutesAccountsTab';
import BatchUpload from './BatchUpload';

function RoutesComp() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading-detail'>
                                    <div className='row p-3'>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>1. Create a Linked Account</h3>
                                            <p style={{ textAlign: 'center' }}>Easily add your vendor/seller/service provider account details as a linked account.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>2. Initiate transfers</h3>
                                            <p style={{ textAlign: 'center' }}>Initiate the payment to be transferred to a linked account from your transactions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Payments" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Payments" title="Payments" className='p-4'>
                                        <RpoutesPaymentsTab />
                                    </Tab>
                                    <Tab eventKey="Transfers" title="Transfers" className='p-4'>
                                        <TransfersTab />
                                    </Tab>
                                    <Tab eventKey="Reversals" title="Reversals" className='p-4'>
                                        <RevealsTab />
                                    </Tab>
                                    <Tab eventKey="Accounts" title="Accounts" className='p-4'>
                                        <RoutesAccountsTab />
                                    </Tab>
                                    <Tab eventKey="Batch Upload" title="Batch Upload" className='p-4'>
                                        <BatchUpload />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default RoutesComp