import React, { useState } from 'react';
import { GiWallet } from "react-icons/gi";
import { TbCreditCardRefund } from "react-icons/tb";
import { FaFirstOrderAlt } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { RiCheckDoubleFill } from "react-icons/ri";
import { FaFileInvoice } from "react-icons/fa";
import { LiaAlipay } from "react-icons/lia";
import { MdPayments } from "react-icons/md";
import { FaRegCreditCard } from "react-icons/fa";
import { BiSolidCreditCardFront } from "react-icons/bi";
import { TbReport } from "react-icons/tb";
import { IoQrCode } from "react-icons/io5";
import { HiDocumentReport } from "react-icons/hi";
import { TbReportAnalytics } from "react-icons/tb";
import { MdOutlineSettingsEthernet } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { MdUnsubscribe } from "react-icons/md";
import { LiaCheckDoubleSolid } from "react-icons/lia";



function ReportsOverview() {
    const [filterInpVal, setFilterInpVal] = useState({
        date: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="container">
                <div className="active-projects style-1">
                    <form>
                        <div className='row'>
                            {/* <div className="col-lg-3 col-md-3 col-sm-12">
                                                <button className="btn btn-primary custom-inp-btn rounded float-start" type="button" onClick={handleSubmitFilterData}><MdOutlineFileDownload /> Download Report</button>
                                            </div> */}
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <label className='custome-label-inp'>Filter Report</label>
                                <div className="form-group">
                                    <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                        <option selected>Select</option>
                                        <option value={'Date-Newest'}>All Reports</option>
                                        <option value={'Date-Oldest'}>Report Type</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="row mt-5 mb-2">
                    <div className="card">
                        <div className="card-body p-2">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><GiWallet /></h3>
                                            <h3 >
                                                Payment Links
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all payments that were created in the selected time range using Payment links.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><TbCreditCardRefund /></h3>
                                            <h3 >
                                                Refunds
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all refunds that were initiated in the selected time range. Details include refund ID, amount, date, corresponding...</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><FaFirstOrderAlt /></h3>
                                            <h3 >
                                                Orders
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all orders that were created in the selected time range. Details include order ID, status, date, amount, amount paid...</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><BiSolidReport /></h3>
                                            <h3 >
                                                Combined Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides all transactions (payments, refunds, adjustments, and transfers) and settlements that were made in the selected time.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdPayments /></h3>
                                            <h3 >
                                                Payments
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all payments that were created in the selected time range. Details include payment ID, status, method, issuer name...</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><LiaCheckDoubleSolid /></h3>
                                            <h3 >
                                                Settlements
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides a list of the settlement(s) in selected time range. It does not include details of the transactions that were settled. Details include...</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><LiaCheckDoubleSolid /></h3>
                                            <h3 >
                                                Settlement Recon
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides a detailed list of all transactions (payments, refunds, and adjustments) against every settlement in selected.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdUnsubscribe /></h3>
                                            <h3 >
                                                Subscriptions Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all subscriptions that were created in the selected time range. Details include subscription ID, subscription.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><FaFileInvoice /></h3>
                                            <h3 >
                                                Monthly Invoice Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report reflects all transactions in a month against your invoice. You can use this to validate the invoice amount.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><TbCreditCardRefund /></h3>
                                            <h3 >
                                                Instant Refunds
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all refunds that were initiated in the selected time range. Details include refund ID, amount, status...</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><CgNotes /></h3>
                                            <h3 >
                                                Custom Transfers with Notes
                                            </h3>
                                        </div>
                                        <p className="customcard-text">Custom Transfers with Notes</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdPayments /></h3>
                                            <h3 >
                                                Payment page
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all payments that were created in the selected time range using Payment Page</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><BiSolidReport />
                                            </h3>
                                            <h3 >
                                                Payment Button Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all payments that were created in the selected time range using Payment Button.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><BiSolidReport />
                                            </h3>
                                            <h3 >
                                                Subscriptions Update Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all subscriptions that were updated in the selected time range</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><BiSolidReport /></h3>
                                            <h3 >
                                                Token Update Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all recurring tokens that were updated in the selected time range.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><IoQrCode /></h3>
                                            <h3 >
                                                QR Codes Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides the details of all the QR Codes created in the selected time range</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdOutlineSettingsEthernet /></h3>
                                            <h3 >
                                                On Demand Settlement
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all On Demand Settlements in selected time range.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><TbReportAnalytics /></h3>
                                            <h3 >
                                                SubMerchant Report for Non Platform Partner
                                            </h3>
                                        </div>
                                        <p className="customcard-text">SubMerchant Report for Non Platform Partner.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><HiDocumentReport /></h3>
                                            <h3 >
                                                Vendor standard Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">Vendor standard Report.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><IoQrCode /></h3>
                                            <h3 >
                                                QR Code Report with Pay_Id
                                            </h3>
                                        </div>
                                        <p className="customcard-text">QR Code Report.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><TbReport /></h3>
                                            <h3 >
                                                Payments Report With Offers
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all payments that were created in the selected time range. Details include payment ID, status, method, issuer name...</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><BiSolidCreditCardFront /></h3>
                                            <h3 >
                                                Custom Identifier status Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides visibility into the current status, time elapsed since last payment and time remaining till closure (for active Customers)</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><FaRegCreditCard /></h3>
                                            <h3 >
                                                Credit Usage Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides all transactions (payments, refunds, adjustments, and transfers) and settlements that were made in the selected time...</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdPayments /></h3>
                                            <h3 >
                                                Bulk Payment Page Customers Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">Bulk Payment Pages Customers Report</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdPayments /></h3>
                                            <h3 >
                                                Bulk Payment Page Payments Report
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all payments that were created in the selected time range using Payment Page</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><LiaAlipay /></h3>
                                            <h3 >
                                                Turbo UPI Payments
                                            </h3>
                                        </div>
                                        <p className="customcard-text">This report provides details of all Upi Turbo payments that were created in the selected time range. Details include payment ID, status, method.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><FaFileInvoice /></h3>
                                            <h3 >
                                                Monthly Invoice
                                            </h3>
                                        </div>
                                        <p className="customcard-text">Download your monthly invoice in one single step.</p>
                                        <div className='btn-center'>
                                            <button className="btn btn-primary" type="button" onClick={'handleSubmitFilterData'}> Download Report</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default ReportsOverview