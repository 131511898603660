import './assets/css/style.css';
import './assets/css/style2.css';
import { Route, Routes, Navigate } from "react-router-dom";
import DashboardPage from './page/dashboardPage';
import RightComp from './common/RightComp';
import LoginPage from './page/loginPage';
import DashbOardRightPage from './page/dashboardRightPage';
import TransactionsPage from './page/transactionsPage';
import SettlementsPage from './page/settlementsPage';
import ReportsPage from './page/reportsPage';
import SubscriptionsPage from './page/subscriptionPage';
import InvoicesPage from './page/invoicesPage';
import PaymentButtonPage from './page/paymentButtonPage';
import QRCodesPage from './page/qrCodesPage';
import PaymentLinksPage from './page/paymentLinksPage';
import PaymentPagesPage from './page/paymentPagesPage';
import RoutesPage from './page/routesPage';
import AbarisPayMeLinkPage from './page/abarisPayMeLinkPage';
import CustomersPage from './page/customersPage';
import OffersPage from './page/offersPage';
import DevelopersPage from './page/developersPage';
import AccountSettingPage from './page/accountSettingPage';
import BusinessPolicyDetail from './components/accountSettingComp/webAndAppSettingTab/businessPolicyDetail/BusinessPolicyDetail';
import BusinessWebDetail from './components/accountSettingComp/webAndAppSettingTab/businessWebDetail/BusinessWebDetail';
import Webhooks from './components/accountSettingComp/webAndAppSettingTab/webHooks/Webhooks';
import ApisKeys from './components/accountSettingComp/webAndAppSettingTab/apisKeys/ApisKeys';
import WhatsApp from './components/accountSettingComp/notificationSettingTab/whatsApp/WhatsApp';
import Sms from './components/accountSettingComp/notificationSettingTab/sms/Sms';
import Email from './components/accountSettingComp/notificationSettingTab/email/Email';
import BusinessDetail from './components/accountSettingComp/businessSettingTab/businessDetail/BusinessDetail';
import CustomerSupportDetail from './components/accountSettingComp/businessSettingTab/customerSupportDetail/CustomerSupportDetail';
import AccountDetail from './components/accountSettingComp/businessSettingTab/accountDetail/AccountDetail';
import ActivationDetail from './components/accountSettingComp/businessSettingTab/activationDetail/ActivationDetail';
import GstDetail from './components/accountSettingComp/businessSettingTab/gstDetail/GstDetail';
import Supporttcket from './components/accountSettingComp/businessSettingTab/supportTicket/Supporttcket';
import Manageteam from './components/accountSettingComp/businessSettingTab/manageTeam/Manageteam';
import Balances from './components/accountSettingComp/paymentsAndRefundsTab/balances/Balances';
import Credits from './components/accountSettingComp/paymentsAndRefundsTab/credits/Credits';
import Reminders from './components/accountSettingComp/paymentsAndRefundsTab/reminders/Reminders';
import TransactionsLimit from './components/accountSettingComp/paymentsAndRefundsTab/transactionsLimit/TransactionsLimit';
import FeeBearer from './components/accountSettingComp/paymentsAndRefundsTab/feeBearer/FeeBearer';
import CaptureAndRefundSetting from './components/accountSettingComp/paymentsAndRefundsTab/captureAndRefundsSetting/CaptureAndRefundSetting';
import SettlementDetail from './components/accountSettingComp/bankAccountAndSettlmentsTab/settlementDetail/SettlementDetail';
import BankAccountDetails from './components/accountSettingComp/bankAccountAndSettlmentsTab/bankAccountDetail/BankAccountDetails';
import FlashCheckout from './components/accountSettingComp/checkoutSetting/flashCheckout/FlashCheckout';
import MandateSummaryPages from './components/accountSettingComp/checkoutSetting/MandateSummaryPages/MandateSummaryPages';
import Branding from './components/accountSettingComp/checkoutSetting/branding/Branding';
import Cards from './components/accountSettingComp/paymentMethods/cards/Cards';
import UpiQr from './components/accountSettingComp/paymentMethods/upiQr/UpiQr';
import NetBanking from './components/accountSettingComp/paymentMethods/netBanking/NetBanking';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/loginPage" element={<LoginPage />} />
        <Route path="/" element={<Navigate to="/loginPage" />} />

        <Route path='admin' element={<DashboardPage />}>
          {/* <Route path='' element={<DashbOardRightPage />} /> */}
          <Route path='' element={<TransactionsPage />} />
          <Route path='transactions' element={<TransactionsPage />} />
          <Route path='settlements' element={<SettlementsPage />} />
          <Route path='reports' element={<ReportsPage />} />
          <Route path='account-settings' element={<AccountSettingPage />} />
          <Route path='account-settings/web-app/business-policy/detail' element={<BusinessPolicyDetail />} />
          <Route path='account-settings/web-app/business-web/detail' element={<BusinessWebDetail />} />
          <Route path='account-settings/web-app/webhooks' element={<Webhooks />} />
          <Route path='account-settings/web-app/api-keys' element={<ApisKeys />} />
          <Route path='account-settings/notification/setting/whatsApp' element={<WhatsApp />} />
          <Route path='account-settings/notification/setting/sms' element={<Sms />} />
          <Route path='account-settings/notification/setting/email' element={<Email />} />
          <Route path='account-settings/business-settings/business/detail' element={<BusinessDetail />} />
          <Route path='account-settings/business-settings/customer-support/detail' element={<CustomerSupportDetail />} />
          <Route path='account-settings/business-settings/account/detail' element={<AccountDetail />} />
          <Route path='account-settings/business-settings/activation/detail' element={<ActivationDetail />} />
          <Route path='account-settings/business-settings/gst/detail' element={<GstDetail />} />
          <Route path='account-settings/business-settings/support-ticket' element={<Supporttcket />} />
          <Route path='account-settings/business-settings/manage-team' element={<Manageteam />} />
          <Route path='account-settings/payments-and-refunds/balances' element={<Balances />} />
          <Route path='account-settings/payments-and-refunds/credits' element={<Credits />} />
          <Route path='account-settings/payments-and-refunds/reminders' element={<Reminders />} />
          <Route path='account-settings/payments-and-refunds/transactions-limit' element={<TransactionsLimit />} />
          <Route path='account-settings/payments-and-refunds/feeBearer' element={<FeeBearer />} />
          <Route path='account-settings/payments-and-refunds/capture-refund-settings' element={<CaptureAndRefundSetting />} />
          <Route path='account-settings/bankAccounts-settlements/settlement/details' element={<SettlementDetail />} />
          <Route path='account-settings/bankAccounts-settlements/bankAccount/details' element={<BankAccountDetails />} />
          <Route path='account-settings/checkout-settings/flash-checkout' element={<FlashCheckout />} />
          <Route path='account-settings/checkout-settings/mandate-summary-page' element={<MandateSummaryPages />} />
          <Route path='account-settings/checkout-settings/branding' element={<Branding />} />
          <Route path='account-settings/payment-methods/cards' element={<Cards />} />
          <Route path='account-settings/payment-methods/UPI-QR' element={<UpiQr />} />
          <Route path='account-settings/payment-methods/netBanking' element={<NetBanking />} />



          <Route path='subscriptions' element={<SubscriptionsPage />} />
          <Route path='invoices' element={<InvoicesPage />} />
          <Route path='payment-buttons' element={<PaymentButtonPage />} />
          <Route path='QR-codes' element={<QRCodesPage />} />
          <Route path='payment-links' element={<PaymentLinksPage />} />
          <Route path='payment-pages-page' element={<PaymentPagesPage />} />
          <Route path='routes' element={<RoutesPage />} />
          <Route path='me-link' element={<AbarisPayMeLinkPage />} />
          <Route path='customers' element={<CustomersPage />} />
          <Route path='offers' element={<OffersPage />} />
          <Route path='developers' element={<DevelopersPage />} />

        </Route>
      </Routes>
    </div>
  );
}

export default App;
