import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AddListCategoryTab({ showCategoryModal, handleCloseCategoryModal }) {

    const [filterInpVal, setFilterInpVal] = useState({
        category: '',
        product_name: '',
        price: '',
        discount_price: '',
        quantity: '',
        image: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <div>
            <Modal show={showCategoryModal} onHide={handleCloseCategoryModal} size="lg"
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="active-projects style-1">
                                    <form>
                                        <div className='row p-4'>
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Category Name</label>
                                                    <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Enter Category Name' name='product_name' value={filterInpVal?.product_name} onChange={handleFilterChange} />
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Description</label>
                                                    <textarea className="form-control loginCustom-inp" id="exampleFormControlTextarea1" placeholder='Enter Description' rows={3} name='description' value={filterInpVal?.description} onChange={handleFilterChange} />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Button variant="danger me-3" onClick={handleCloseCategoryModal}>
                                                    Cancel
                                                </Button>
                                                <Button variant="primary" onClick={handleCloseCategoryModal}>
                                                    Save Category
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1">
                                        <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                            <div className='userTablewrapper'>
                                                <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                                    <thead>
                                                        <tr role="row">
                                                            <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Category Id</th>
                                                            <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Category Name</th>
                                                            <th ></th>
                                                            <th ></th>
                                                            <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" className="odd">
                                                            <td><span>ABC ID</span></td>
                                                            <td><span>ABC Name</span></td>
                                                            <td><span></span></td>
                                                            <td><span></span></td>
                                                            <td><span>Category Description</span></td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td><span>ABC ID</span></td>
                                                            <td><span>ABC Name</span></td>
                                                            <td><span></span></td>
                                                            <td><span></span></td>
                                                            <td><span>Category Description</span></td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td><span>ABC ID</span></td>
                                                            <td><span>ABC Name</span></td>
                                                            <td><span></span></td>
                                                            <td><span></span></td>
                                                            <td><span>Category Description</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddListCategoryTab