import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { SiAmazondocumentdb } from "react-icons/si";

function QRCodesTab() {
    const [filterInpVal, setFilterInpVal] = useState({
        status: '',
        qr_code_id: '',
        qr_name: '',
        customer_name: '',
        customer_email: '',
        customer_contact: '',
        notes: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <LiaHandsHelpingSolid /> Need Help? Take a Tour
                            </Link>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button">+ Create QR Codes</button>
                        </div>
                    </div>
                </div>



                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>QR Code Status</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Status</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Active'}>Active</option>
                                                    <option value={'Closed'}>Closed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>QR Code ID</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='QR Code ID' name='qr_code_id' value={filterInpVal?.qr_code_id} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>QR Name</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='QR Name' name='qr_name' value={filterInpVal?.qr_name} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Customer Name</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Customer Name' name='customer_name' value={filterInpVal?.customer_name} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Customer Email</label>
                                                <input type="email" className="form-control loginCustom-inp" id="usr" placeholder='Customer Email' name='customer_email' value={filterInpVal?.customer_email} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Customer Contact</label>
                                                <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Customer Contact' name='customer_contact' value={filterInpVal?.customer_contact} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Notes</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Notes' name='notes' value={filterInpVal?.notes} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}><i className="fas fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>QR Code ID</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>QR Usage</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Description</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Amount Received</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Status</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>QR Code ID</span></td>
                                                    <td><span>Usage</span></td>
                                                    <td><span>ABC Desc</span></td>
                                                    <td >
                                                        <span>500</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>QR Code ID</span></td>
                                                    <td><span>Usage</span></td>
                                                    <td><span>ABC Desc</span></td>
                                                    <td >
                                                        <span>500</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>QR Code ID</span></td>
                                                    <td><span>Usage</span></td>
                                                    <td><span>ABC Desc</span></td>
                                                    <td >
                                                        <span>500</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QRCodesTab