import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import FeeBearerTab from './FeeBearerTab';

function FeeBearer() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Fee Bearer" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Fee Bearer" title="Fee Bearer" className='p-4'>
                                        <FeeBearerTab />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default FeeBearer