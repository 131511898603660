import React from 'react'
import PaymentButtonsComp from '../../components/paymentButtonsComp/PaymentButtonsComp'

function PaymentButtonPage() {
    return (
        <>
            <PaymentButtonsComp />
        </>
    )
}
export default PaymentButtonPage