import React from 'react'

function GstDetailTab() {
    return (
        <div>
            <h4 className='text-danger mb-3'>GSTIN information</h4>
            <p className='mt-5'>GST addition is not supported for your business type. You can create a new abarispay Account as a Non- Individual business type and link GST to it.</p>

        </div>
    )
}

export default GstDetailTab