import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";

function BankAccountDetailsTab() {
    const [filterInpVal, setFilterInpVal] = useState({
        customer_email: '',
        card_expiring_in: '',
        subscription_completing_in: '',
        plan_id: '',
        status: '',
        subscription_id: '',
        count: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">
                <h4 className='mb-3'>Active Bank Account</h4>
                <p style={{ color: '#2f8c98' }}>This is the bank account where settlements are processed and collected payments will be deposited in.</p>
                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <button className="btn btn-primary custom-inp-btn rounded" type="button" >Change Bank Account</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Beneficiary name</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Account number</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>IFSC code</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Email Address: activate to sort column ascending" style={{ width: '140.45px' }}>Updated on</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Gender: activate to sort column ascending" style={{ width: '85.4px' }}>
                                                        Settlements</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span className='text-primary'>GHULAM MOINUDDIN</span></td>
                                                    <td><span>587718210000098</span></td>
                                                    <td >
                                                        <span>BKID0009997</span>
                                                    </td>

                                                    <td>
                                                        <span>30/06/2024</span>
                                                    </td>
                                                    <td>
                                                        <span style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '5px', color: 'white', marginRight: '16px' }}>
                                                            Active
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span className='text-primary'>GHULAM MOINUDDIN</span></td>
                                                    <td><span>587718210000098</span></td>
                                                    <td >
                                                        <span>BKID0009997</span>
                                                    </td>

                                                    <td>
                                                        <span>30/06/2024</span>
                                                    </td>
                                                    <td>
                                                        <span style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '5px', color: 'white', marginRight: '16px' }}>
                                                            Active
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span className='text-primary'>GHULAM MOINUDDIN</span></td>
                                                    <td><span>587718210000098</span></td>
                                                    <td >
                                                        <span>BKID0009997</span>
                                                    </td>

                                                    <td>
                                                        <span>30/06/2024</span>
                                                    </td>
                                                    <td>
                                                        <span style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '5px', color: 'white', marginRight: '16px' }}>
                                                            Active
                                                        </span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BankAccountDetailsTab