import React from 'react';
import { Link } from 'react-router-dom';
import { SiAmazondocumentdb } from "react-icons/si";

function ManageTeamtab() {
    return (
        <>
            <div className='p-3'>
                <h4>2-Step verification to the team</h4>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Enable / Disable</label>
                </div>
                <div className='mt-3'>
                   <p>2-step verification will be enforced to all the team members who have access to this Dashboard.</p>
                   <p><strong>NOTE:-</strong> This setting requires 2-step verification set up on your account</p>
                </div>
            </div>

            <div className="row mt-2 mb-2">
                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <button className='btn btn-primary rounded'>Invite New Member</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Role</th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>abc@gmail.com</span></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>abc@gmail.com</span></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>abc@gmail.com</span></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>abc@gmail.com</span></td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 className='mt-4 mb-2'>Team Members (4)</h5>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Member</th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Role</th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Phone Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC Member</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>9988776655</span></td>
                                                </tr>

                                                <tr role="row" className="odd">
                                                    <td><span>ABC Member</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>9988776655</span></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC Member</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>9988776655</span></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC Member</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>ABC Role</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>9988776655</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageTeamtab