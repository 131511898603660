import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { SiAmazondocumentdb } from "react-icons/si";

function INvoicesTab() {
    return (
        <div className="row mt-2 mb-2">

            <div className="col-lg-12">
                <div className='row p-2'>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                            <LiaHandsHelpingSolid /> Need Help? Take a Tour
                        </Link>
                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                            <SiAmazondocumentdb /> Documentation
                        </Link>
                        <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" >+ Create Invoice</button>
                    </div>
                </div>
            </div>

            <div className="col-lg-12">
                <h6 style={{ textAlign: 'center', marginTop: '80px' }}>There are no invoices yet!! Start creating new invoices now.</h6>
            </div>

        </div>
    )
}

export default INvoicesTab