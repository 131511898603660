import React from 'react'

function AccountDetailTab() {
    return (
        <div className='p-3'>
            <div className="row">

                <div className="col-xl-12">
                    <h4 className='m-2'>Your Account Details</h4>
                    <div className="card">
                        <div className="card-body p-3">

                            <h4>Display Name:   <span style={{ color: '#2f8c98', marginLeft: '15px' }}>GHULAM MOINUDDIN</span></h4>
                            <div className='mb-4'>
                                <button className='btn btn-primary ms-2 pt-1 pb-1 ps-4 pe-4'>Edit</button>
                            </div>

                            <h4>Name:   <span style={{ color: '#2f8c98', marginLeft: '15px' }}>GHULAM MOINUDDIN</span></h4>
                            <div className='mb-4'>
                                <button className='btn btn-primary ms-2 pt-1 pb-1 ps-4 pe-4'>Edit</button>
                            </div>
                            <h4>Phone Number:   <span style={{ color: '#2f8c98', marginLeft: '15px' }}>7886457556</span></h4>
                            <div className='mb-4'>
                                <button className='btn btn-primary ms-2 pt-1 pb-1 ps-4 pe-4'>Edit</button>
                            </div>
                            <h4>Email:   <span style={{ color: '#2f8c98', marginLeft: '15px' }}>abc123@gmail.com</span></h4>
                            <div className='mb-4'>
                                <button className='btn btn-primary ms-2 pt-1 pb-1 ps-4 pe-4'>Edit</button>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AccountDetailTab