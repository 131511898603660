import React from 'react'
import TransactionComp from '../../components/loginComp/transactionsComp/TransactionComp'

function TransactionsPage() {
    return (
        <>
            <TransactionComp />
        </>
    )
}

export default TransactionsPage