import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PaymentButtonsTab from './PaymentButtonsTab';
import SubscriptionButtonsTab from './SubscriptionButtonsTab';

function PaymentButtonsComp() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading-detail'>
                                    <div className='row p-3'>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>1. Create a Button</h3>
                                            <p style={{ textAlign: 'center' }}>Start by creating a Payment Button to collect online payments or donations.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>2. Copy and Paste the Code</h3>
                                            <p style={{ textAlign: 'center' }}>Get a single line code that you put on your website or blog to enable online payments.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>3. Receive Payments</h3>
                                            <p style={{ textAlign: 'center' }}>Customers and supports will use this button to make payments on your website or blog.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Payment Buttons" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Payment Buttons" title="Payment Buttons" className='p-4'>
                                        <PaymentButtonsTab />
                                    </Tab>
                                    <Tab eventKey="Subscription Buttons" title="Subscription Buttons" className='p-4'>
                                        <SubscriptionButtonsTab />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PaymentButtonsComp