import React from 'react';
import { Link } from 'react-router-dom';
import { FiAlertTriangle } from "react-icons/fi";
import { SiAmazondocumentdb } from "react-icons/si";
import { useState } from 'react';
import ManageBalanceAlertsModal from './ManageBalanceAlertsModal';
import AddFundModal from './AddFundModal';

function BalancesTab() {
    const [show, setShow] = useState(false);
    const [showAddFundModal, setShowAddFundModal] = useState(false);

    const openManageAlertModal = () => {
        setShow(true)
    };
    const handleClose = () => {
        setShow(false)
    };
    const openAddFundModal = () => {
        setShowAddFundModal(true)
    };
    const handleCloseAddFundModal = () => {
        setShowAddFundModal(false)
    };


    return (
        <>
            <div className="row mt-2 mb-2">
                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <Link to={'#'} onClick={openManageAlertModal} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <FiAlertTriangle /> Manage Alerts
                            </Link>
                        </div>
                    </div>
                </div>
                <h4 className='mt-4 mb-4'><strong>NOTE:</strong> Standard TDR charges applies on adding funds</h4>
                <div className="col-lg-12">
                    <div className="card">
                        <div className='row p-2'>
                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '60px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <h5 style={{ fontSize: '20px' }}>Current Balance: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>Reserve Balance: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                        <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={openAddFundModal}>Add Funds</button>
                                    </div>
                                    <p>Add funds to your reserve balance to increase the negative balance limit. Thinking of withdrawing your reserve balance? <Link to={"#"} className='text-primary'>Contact Us</Link></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <ManageBalanceAlertsModal show={show} handleClose={handleClose} />
            <AddFundModal showAddFundModal={showAddFundModal} handleCloseAddFundModal={handleCloseAddFundModal} />




        </>
    )
}

export default BalancesTab