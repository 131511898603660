import React, { useState } from 'react';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

function Disputes() {
    const [filterInpVal, setFilterInpVal] = useState({
        dispute_id: '',
        payment_id: '',
        status: '',
        notes: '',
        count: '',
    });

    const { RangePicker } = DatePicker;
    const rangePresets = [
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 14 Days',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            let dateval = `${dateStrings[0]} to ${dateStrings[1]}`
            // setPickedDateVal(dateval)
        } else {
            console.log('Clear');
        }
    };

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">
                <div className="col-lg-12">
                    {/* {loading && <Loadar />} */}
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Dispute Id</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Dispute Id' name='dispute_id' value={filterInpVal?.dispute_id} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Payment Id</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Payment Id' name='payment_id' value={filterInpVal?.payment_id} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Duration</label>
                                                <Space direction="vertical" size={12} >
                                                    <RangePicker presets={rangePresets} onChange={onRangeChange} className=' loginCustom-inp' style={{ paddingBottom: '15px' }} />
                                                </Space>
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Dispute Type</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Dispute Type</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Retrival'}>Retrival</option>
                                                    <option value={'Chargeback'}>Chargeback</option>
                                                    <option value={'Pre Arbritration'}>Pre Arbritration</option>
                                                    <option value={'Arbritration'}>Arbritration</option>
                                                    <option value={'Fraud'}>Fraud</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Dispute State</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Dispute State</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Open'}>Open</option>
                                                    <option value={'under Review'}>under Review</option>
                                                    <option value={'Lost'}>Lost</option>
                                                    <option value={'Won'}>Won</option>
                                                    <option value={'Closed'}>Closed</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}><i className="fas fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Dispute ID</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Payment ID</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Amount</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Type</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Respond By</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Gender: activate to sort column ascending" style={{ width: '85.4px' }}>Created At</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>001</span></td>
                                                    <td><span>88976</span></td>
                                                    <td><span>1500</span></td>
                                                    <td><span></span></td>
                                                    <td><span></span></td>
                                                    <td >
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>001</span></td>
                                                    <td><span>88976</span></td>
                                                    <td><span>1500</span></td>
                                                    <td><span></span></td>
                                                    <td><span></span></td>
                                                    <td >
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                               

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Disputes