import React from 'react';
import { CgMoreR } from "react-icons/cg";
import { Link } from 'react-router-dom';

function NetBankingTab() {
    return (
        <div className='row'>

            <div className='col-lg-12'>
                <div className='overview-heading'>
                    <div className='text-sec'>
                        <h4>Netbanking</h4>
                        <p>All Indian Banks</p>
                    </div>
                    <div className='button-sec'>
                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                            <CgMoreR /> Know More About Payment Methods
                        </Link>
                    </div>
                </div>
            </div>

            <div>
                {/* <h4 className='mt-5'>Domestic Cards</h4> */}
                <h4 className='mt-5'>Retail Net Banking</h4>
                <p>Direct Net Banking With Customers</p>
            </div>


            <div className='mt-5'>
                <button className='btn btn-primary'>
                    Active Banks
                </button>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className='row p-2'>

                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: '', boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                <img src='https://cdn.razorpay.com/static/assets/instrument-request/visa.png' style={{ width: '100px', height: '100px' }} />
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Visa Cards</span>
                                <span style={{ padding: '5px 10px', marginLeft: '180px', color: 'white', borderRadius: '5px', backgroundColor: 'green', fontWeight: '600' }}>Activated</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: '', boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                <img src='https://cdn.razorpay.com/static/assets/instrument-request/masterCard.png' style={{ width: '100px', height: '100px' }} />
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Master Card</span>
                                <span style={{ padding: '5px 10px', marginLeft: '180px', color: 'white', borderRadius: '5px', backgroundColor: 'green', fontWeight: '600' }}>Activated</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: '', boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                <img src='https://cdn.razorpay.com/static/assets/instrument-request/rupay.png' style={{ width: '100px', height: '100px' }} />
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Rupay Cards</span>
                                <span style={{ padding: '5px 10px', marginLeft: '180px', color: 'white', borderRadius: '5px', backgroundColor: 'green', fontWeight: '600' }}>Activated</span>
                            </div>
                        </div>


                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: '', boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                <img src='https://cdn.razorpay.com/static/assets/instrument-request/maestro.png' style={{ width: '100px', height: '100px' }} />
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Maestro</span>
                                <button className='btn btn-primary' style={{ marginLeft: '170px' }}>Request</button>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: '', boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                <img src='https://cdn.razorpay.com/static/assets/instrument-request/diners.png' style={{ width: '100px', height: '100px' }} />
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Dinners Club</span>
                                <button className='btn btn-primary' style={{ marginLeft: '170px' }}>Request</button>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: '', boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                <img src='https://cdn.razorpay.com/static/assets/instrument-request/amex.png' style={{ width: '100px', height: '100px' }} />
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Amex Cards</span>
                                <button className='btn btn-primary' style={{ marginLeft: '170px' }}>Request</button>
                            </div>
                        </div>





                    </div>
                </div>
            </div>
        </div>
    )
}

export default NetBankingTab