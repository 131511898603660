import React from 'react';

function BusinessPolicyDetailTab() {


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div style={{ textAlign: 'center', margin: '20px auto', width: '400px' }}>
                                <button className="btn btn-success custom-inp-btn rounded me-4" type="button">Verified</button>
                            </div>
                            <h5 style={{ textAlign: 'center', margin: '20px auto', width: '400px' }}>Your website/app details are verified and up to date. You can make changes anytime depending on your business policy if required</h5>
                            <div style={{ textAlign: 'center', margin: '20px auto', width: '400px' }}>
                                <button className="btn btn-primary custom-inp-btn rounded me-4" type="button">View / Update Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessPolicyDetailTab