import React from 'react'
import RoutesComp from '../../components/routesComp/RoutesComp'

function RoutesPage() {
    return (
        <>
            <RoutesComp />
        </>
    )
}

export default RoutesPage