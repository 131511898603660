import React, { useState } from 'react';
import { FaGlobe } from "react-icons/fa";

function BusinessWebDetailTab() {

    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" >+ Add Website / App Details</button>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <p>This is the website/app where payments can be collected after integration of the payment gateway</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card">
                        <div className='row p-2'>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: '40px' }}><FaGlobe /></div>
                                    <h5 style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', marginTop: '15px' }}>Website Url</h5>
                                    <p style={{ textAlign: 'center' }}>-----</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: '40px' }}><FaGlobe /></div>
                                    <h5 style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', marginTop: '15px' }}>AppStore Url</h5>
                                    <p style={{ textAlign: 'center' }}>-----</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: '40px' }}><FaGlobe /></div>
                                    <h5 style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', marginTop: '15px' }}>PlayStore Url</h5>
                                    <p style={{ textAlign: 'center' }}>-----</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <p style={{ textAlign: 'center' }}>Additional Business Website/App</p>
            </div>

        </>
    )
}

export default BusinessWebDetailTab