import React from 'react'
import QRCodesComp from '../../components/qrCodesComp/QRCodesComp'

function QRCodesPage() {
    return (
        <>
            <QRCodesComp />
        </>
    )
}

export default QRCodesPage