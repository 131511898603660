import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function AddRefundCreditsModal({ show4, handleClose4 }) {
    return (
        <>
            <div>
                <Modal
                    show={show4}
                    onHide={handleClose4}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Add Refund Credits
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className='m-0'><b>Note:</b>  Funds can only be added from bank account linked to your AbarisPay account.</h5>
                        <div className='mt-5'>
                            <h6>Select Payment Method</h6>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Bank Transfer (IMPS, NEFT, RGTS)
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    UPI
                                </label>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose4}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleClose4}>
                            Continue
                        </Button>
                    </Modal.Footer>

                </Modal>
            </div>
        </>
    )
}

export default AddRefundCreditsModal