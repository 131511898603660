import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiAlertTriangle } from "react-icons/fi";
import { SiAmazondocumentdb } from "react-icons/si";
import ManageCreditAlertsModal from './ManageCreditAlertsModal';
import ApplyCouponCodeModal from './ApplyCouponCodeModal';
import AddFreeCreditsModal from './AddFreeCreditsModal';
import AddRefundCreditsModal from './AddRefundCreditsModal';
import RefundCreditHistoryModal from './RefundCreditHistoryModal';
import FeeCreditsHistoryModal from './FeeCreditsHistoryModal';
import ViewPastCoupnsModal from './ViewPastCoupnsModal';

function Creditstab() {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);

    const openManageAlertModal = () => {
        setShow(true)
    };
    const handleClose = () => {
        setShow(false)
    };
    const openApplyCouponCodeModal = () => {
        setShow2(true)
    };
    const handleClose2 = () => {
        setShow2(false)
    };
    const openAddFeeCreditModal = () => {
        setShow3(true)
    };
    const handleClose3 = () => {
        setShow3(false)
    };
    const openRefundCreditsModal = () => {
        setShow4(true)
    };
    const handleClose4 = () => {
        setShow4(false)
    };
    const openRefundCreditHistoryModal = () => {
        setShow5(true)
    };
    const handleClose5 = () => {
        setShow5(false)
    };
    const openFeeCreditsHistoryModal = () => {
        setShow6(true)
    };
    const handleClose6 = () => {
        setShow6(false)
    };
    const openViewPastCouponsModal = () => {
        setShow7(true)
    };
    const handleClose7 = () => {
        setShow7(false)
    };

    return (
        <>
            <div className="row mt-2 mb-2">
                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <Link to={'#'} onClick={openManageAlertModal} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <FiAlertTriangle /> Manage Alerts
                            </Link>
                        </div>
                    </div>
                </div>
                <h4 className='mt-4 mb-4'><strong>NOTE:</strong> Standard TDR charges applies on adding funds</h4>



                <div className="col-lg-12">
                    <div className="card">
                        <h5 style={{ textAlign: 'center', margin: '20px', color: '#2f8c98' }}>These credits can not be applied for credit cards transactions.</h5>
                        <div className='row p-2'>

                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>Amount Credits: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                        <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={openApplyCouponCodeModal}>Apply Coupon Code</button>
                                    </div>
                                    <p>Transactions worth amount credits will be free of any transaction fee.</p>
                                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '30px' }}>
                                        <Link to={"#"} className='text-primary' onClick={openViewPastCouponsModal}>View Past Coupons</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>Refund Credits: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                        <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={openRefundCreditsModal}>Add Refund Credits</button>
                                    </div>
                                    <p>Do not want to refund from your settled amounts? Use refund credits.</p>
                                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '30px' }}>
                                        <Link to={"#"} className='text-primary' onClick={openRefundCreditHistoryModal}>View History</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 p-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '40px 20px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h5 style={{ fontSize: '20px', margin: '0' }}>Fee Credits: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                        <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={openAddFeeCreditModal}>Add Fee Credits</button>
                                    </div>
                                    <p>Do not want to refund from your settled amounts? Use refund credits.</p>
                                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '30px' }}>
                                        <Link to={"#"} className='text-primary' onClick={openFeeCreditsHistoryModal}>View History</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <ManageCreditAlertsModal show={show} handleClose={handleClose} />
                <ApplyCouponCodeModal show2={show2} handleClose2={handleClose2} />
                <AddFreeCreditsModal show3={show3} handleClose3={handleClose3} />
                <AddRefundCreditsModal show4={show4} handleClose4={handleClose4} />
                <RefundCreditHistoryModal show5={show5} handleClose5={handleClose5} />
                <FeeCreditsHistoryModal show6={show6} handleClose6={handleClose6} />
                <ViewPastCoupnsModal show7={show7} handleClose7={handleClose7} />

            </div>



        </>
    )
}

export default Creditstab