import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import QRCodesTab from './QRCodesTab';
import PaymentsTab from './PaymentsTab';

function QRCodesComp() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading-detail'>
                                    <div className='row p-3'>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>1. Create QR Code</h3>
                                            <p style={{ textAlign: 'center' }}>Create as many QR codes as required, and print them or share with others.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>2. Receive Payments</h3>
                                            <p style={{ textAlign: 'center' }}>Customers can pay by scanning your QR codes using supported apps.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="QR Codes" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="QR Codes" title="QR Codes" className='p-4'>
                                        <QRCodesTab />
                                    </Tab>
                                    <Tab eventKey="Payments" title="Payments" className='p-4'>
                                        <PaymentsTab />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default QRCodesComp