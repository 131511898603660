import React from 'react'
import Header from '../../common/Header';
import { Outlet } from "react-router-dom";
import Aside from '../../common/aside/Aside';

function DashboardPage() {
    return (
        <>
            <div id="main-wrapper" className="show menu-toggle">
                <Header />
                <div className="main-wrapper-details">
                    <Aside />
                    <div
                        className="content-body"
                        style={{ minHeight: "816px" }}
                    >
                        {/* <PageTitle /> */}
                        <div className="">
                            <Outlet></Outlet>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DashboardPage