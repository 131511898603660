import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import SettlementTabComp from './SettlementTabComp';

function Settlements() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading'>
                                    <div className='text-sec'>
                                        <h4>Overview</h4>
                                        <p>50 mins ago</p>
                                        <button>Refresh</button>
                                    </div>
                                    <div className='button-sec'>
                                        <button className='me-3'>My Settlement Cycle</button>
                                        <button>Documentation</button>
                                    </div>
                                </div>
                                <div className='overview-heading-detail'>
                                    <div className='row p-2'>
                                        <div className='col-lg-3 ps-2 pt-2 pe-2 pb-5'>
                                            <h5 className='heading-txt'>Current Balance: <span>1000</span></h5>
                                        </div>
                                        <div className='col-lg-3 ps-2 pt-2 pe-2 pb-5'>
                                            <h5 className='heading-txt'>Settlement Due Today: <span>1000</span></h5>
                                        </div>
                                        <div className='col-lg-3 ps-2 pt-2 pe-2 pb-5'>
                                            <h5 className='heading-txt'>Previous Settlement: <span>1000</span></h5>
                                        </div>
                                        <div className='col-lg-3 ps-2 pt-2 pe-2 pb-5'>
                                            <h5 className='heading-txt'>Upcoming Settlement: <span>1000</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Settlements" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Settlements" title="Settlements" className='p-4'>
                                        <SettlementTabComp />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settlements