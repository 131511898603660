import React from 'react'
import PaymentPagesComp from '../../components/paymentPagesComp/PaymentPagesComp'

function PaymentPagesPage() {
    return (
        <>
            <PaymentPagesComp />
        </>
    )
}

export default PaymentPagesPage