import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function ApplyCouponCodeModal({ show2, handleClose2 }) {
    return (
        <div> return (
            <>
                <div>
                    <Modal
                        show={show2}
                        onHide={handleClose2}
                        dialogClassName="modal-90w"

                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Apply Coupon Code
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="row mt-2 mb-2">
                                <div className="col-lg-12">
                                    <div className="active-projects style-1">
                                        <form>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="form-group">
                                                        <label className='custome-label-inp'>Coupon Code</label>
                                                        <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Enter Coupon Code' name='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose2}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleClose2}>
                                Apply
                            </Button>
                        </Modal.Footer>

                    </Modal>
                </div>
            </>
            )</div>
    )
}

export default ApplyCouponCodeModal