import React from 'react'
import { Link } from 'react-router-dom'

function ActivationDetailTab() {
    return (
        <div className='p-3'>
            <div className="row">
                <h4 className='m-2'>Activation Details</h4>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">

                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <th><span>Account Activation:</span></th>
                                                    <td><Link to={"#"} className='text-primary' style={{fontWeight:'600' }}>View Activation Form</Link></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <th><span>Account Activated On:</span></th>
                                                    <td><span style={{ color: '#2f8c98' }}>Jun 29 2024, 12:24:18 pm</span></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <th><span>Account Access:</span></th>
                                                    <td><span style={{ color: '#2f8c98' }}>Limited</span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ActivationDetailTab