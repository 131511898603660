import React from 'react';
import { Link } from 'react-router-dom';
import { FiAlertTriangle } from "react-icons/fi";
import { SiAmazondocumentdb } from "react-icons/si";

function RemindersTab() {
    return (

        <>
            <div className="col-lg-12">
                <div className='row p-2'>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                            <SiAmazondocumentdb /> Documentation
                        </Link>
                    </div>
                </div>
            </div>
            <div className='p-3'>
                <h4>Payment Links reminders</h4>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">OFF / ON</label>
                </div>
                <div className='mt-3'>
                    <h5>Send collection reminders to customers automatically if a payment link hasnt been paid.</h5>
                </div>
            </div>
        </>

    )
}

export default RemindersTab