import React, { useState } from 'react'

function Orders() {
    const [filterInpVal, setFilterInpVal] = useState({
        order_id: '',
        receipt: '',
        status: '',
        notes: '',
        count: '',
    });



    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">
                <div className="col-lg-12">
                    {/* {loading && <Loadar />} */}
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Order Id</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Order Id' name='order_id' value={filterInpVal?.order_id} onChange={handleFilterChange} />
                                            </div>
                                        </div>


                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Receipt</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Receipt' name='receipt' value={filterInpVal?.receipt} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Notes</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Notes' name='notes' value={filterInpVal?.notes} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Status</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Status</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Processed'}>Processed</option>
                                                    <option value={'Processing'}>Processing</option>
                                                    <option value={'Failed'}>Failed</option>
                                                </select>
                                            </div>
                                        </div>



                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Count</label>
                                                <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Count' name='count' value={filterInpVal?.count} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}><i className="fas fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Order ID</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Amount</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Attempts</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Receipt</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Gender: activate to sort column ascending" style={{ width: '85.4px' }}>Created At</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>00012</span></td>
                                                    <td><span>2000</span></td>
                                                    <td><span>02</span></td>
                                                    <td><span>9008</span></td>
                                                    <td >
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>00012</span></td>
                                                    <td><span>2000</span></td>
                                                    <td><span>02</span></td>
                                                    <td><span>9008</span></td>
                                                    <td >
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Orders