import React from 'react';
import { Link } from 'react-router-dom';
import { RiSecurePaymentLine } from "react-icons/ri";
import { CgWebsite } from "react-icons/cg";
import avtarImg from '../../assets/icons/flaticon/avtar.jpg';
import { MdNotificationsActive } from "react-icons/md";
import { GrBusinessService } from "react-icons/gr";
import { TbCreditCardRefund } from "react-icons/tb";
import { IoBagCheckOutline } from "react-icons/io5";
import { TbBuildingBank } from "react-icons/tb";

function AccountSettingComp() {
    return (
        <>
            <div className="container">
                <div className="active-projects style-1">
                    <h4 className='me-3'>Profile</h4>
                    <div className='row '>
                        <div className="card">
                            <div className="card-body p-2">
                                <div className='row p-2'>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div>
                                            <div className="card" style={{ width: '18rem' }}>
                                                <img style={{ height: '150px' }} className="card-img-top" src={avtarImg} alt="Card image cap" />
                                                <div className="card-body">
                                                    <h4 style={{ textAlign: 'center' }} className="card-title">G MOINUDDIN</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                                        <h4>Merchant ID:   <span style={{ color: '#2f8c98', marginLeft: '15px' }}>OSU7DhdbauG</span></h4>
                                        <h4>Login email:   <span style={{ color: '#2f8c98', marginLeft: '15px' }}>abarispay12@gmail.com</span></h4>
                                        <h4>Password:   <span style={{ color: '#2f8c98', marginLeft: '15px' }}>abc123</span></h4>
                                        <h4>2-step verification   <button className='btn btn-primary ms-2'>Click</button></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h4 className='me-3'>Account and product settings</h4>
                        </div>
                    </div>
                </div>

                <div className="row mt-2 mb-2">
                    <div className="card">
                        <div className="card-body p-2">
                            <div className='row'>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><CgWebsite /></h3>
                                            <h3 >
                                                Website and app settings
                                            </h3>
                                        </div>
                                        <Link to="web-app/business-policy/detail" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Business Policy detail
                                        </Link>
                                        <Link to="web-app/business-web/detail" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Business website detail
                                        </Link>
                                        <Link to="web-app/webhooks" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Webhooks
                                        </Link>
                                        <Link to="web-app/api-keys" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            API Keys
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><MdNotificationsActive /></h3>
                                            <h3 >
                                                Notification settings
                                            </h3>
                                        </div>

                                        <Link to="notification/setting/whatsApp" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            WhatsApp
                                        </Link>
                                        <Link to="notification/setting/sms" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            SMS
                                        </Link>
                                        <Link to="notification/setting/email" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            EMail
                                        </Link>

                                    </div>
                                </div>



                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><GrBusinessService /></h3>
                                            <h3 >
                                                Business settings
                                            </h3>
                                        </div>

                                        <Link to="business-settings/business/detail" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Business details
                                        </Link>
                                        <Link to="business-settings/customer-support/detail" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Customer support details
                                        </Link>
                                        <Link to="business-settings/account/detail" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Account details
                                        </Link>
                                        <Link to="business-settings/activation/detail" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Activation details
                                        </Link>
                                        <Link to="business-settings/manage-team" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Manage team
                                        </Link>
                                        <Link to="business-settings/gst/detail" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            GST details
                                        </Link>
                                        <Link to="business-settings/support-ticket" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Support tickets
                                        </Link>



                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><TbCreditCardRefund /></h3>
                                            <h3 >
                                                Payments and refunds
                                            </h3>
                                        </div>

                                        <Link to="payments-and-refunds/balances" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Balances
                                        </Link>


                                        <Link to="payments-and-refunds/transactions-limit" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Transaction limits
                                        </Link>
                                        <Link to="payments-and-refunds/feeBearer" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Fee bearer
                                        </Link>
                                        <Link to="payments-and-refunds/credits" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Credits
                                        </Link>
                                        <Link to="payments-and-refunds/reminders" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Reminders
                                        </Link>
                                        <Link to="payments-and-refunds/capture-refund-settings" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Capture and refund settings
                                        </Link>

                                    </div>
                                </div>


                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><IoBagCheckOutline /></h3>
                                            <h3 >
                                                Checkout settings
                                            </h3>
                                        </div>

                                        <Link to="checkout-settings/flash-checkout" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Flash checkout
                                        </Link>
                                        <Link to="checkout-settings/mandate-summary-page" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Mandate summary page
                                        </Link>
                                        <Link to="checkout-settings/branding" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Branding
                                        </Link>

                                        {/* <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Trusted badge
                                        </Link> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><TbBuildingBank /></h3>
                                            <h3 >
                                                Bank accounts and settlements
                                            </h3>
                                        </div>
                                        <Link to="bankAccounts-settlements/settlement/details" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Settlement details
                                        </Link>
                                        <Link to="bankAccounts-settlements/bankAccount/details" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Bank account details
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className='report-content-wrapper'>
                                        <div className="custom-card-heading">
                                            <h3 style={{ marginRight: '8px', color: '#0d99ff' }}><RiSecurePaymentLine /></h3>
                                            <h3 >
                                                Payment methods
                                            </h3>
                                        </div>
                                        <Link to="payment-methods/cards" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Cards
                                        </Link>

                                        {/* <Link to="payment-methods/UPI-QR" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            UPI/QR
                                        </Link> */}
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            UPI/QR
                                        </Link>

                                        {/* <Link to="payment-methods/netBanking" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Net Banking
                                        </Link> */}
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Net Banking
                                        </Link>

                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            EMI
                                        </Link>
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Pay Later
                                        </Link>
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            Wallet
                                        </Link>
                                        <Link to="#" className='text-primary mb-1' style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            International Payments
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountSettingComp