import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ReportsOverview from './ReportsOverview';
import ReportsDownloads from './ReportsDownloads';
import ReportSchedule from './ReportSchedule';

function ReportsTab() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Overview" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Overview" title="Overview" className='p-4'>
                                        <ReportsOverview />
                                    </Tab>
                                    <Tab eventKey="Downloads" title="Downloads" className='p-4'>
                                        <ReportsDownloads />
                                    </Tab>
                                    <Tab eventKey="Schedules" title="Schedules" className='p-4'>
                                        <ReportSchedule />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportsTab