import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AddNewProduct({ show, handleClose }) {

    const [filterInpVal, setFilterInpVal] = useState({
        category: '',
        product_name: '',
        price: '',
        discount_price: '',
        quantity: '',
        image: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="active-projects style-1">
                                    <form>
                                        <div className='row p-4'>

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Product Name</label>
                                                    <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Enter Product Name' name='product_name' value={filterInpVal?.product_name} onChange={handleFilterChange} />
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Price</label>
                                                    <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Enter Price' name='price' value={filterInpVal?.price} onChange={handleFilterChange} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Discount Price</label>
                                                    <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Enter Discount Price' name='discount_price' value={filterInpVal?.discount_price} onChange={handleFilterChange} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Quantity</label>
                                                    <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Enter Quantity' name='quantity' value={filterInpVal?.quantity} onChange={handleFilterChange} />
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Select Category</label>
                                                    <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='category' value={filterInpVal?.category} onChange={handleFilterChange}>
                                                        <option selected>Select Category</option>
                                                        <option value={'Active'}>ABC</option>
                                                        <option value={'InActive'}>DEF</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Upload Image</label>
                                                    <input type="file" className="form-control loginCustom-inp" id="usr" placeholder='' name='image' value={filterInpVal?.image} onChange={handleFilterChange} />
                                                </div>
                                            </div>

                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Description</label>
                                                    <textarea className="form-control loginCustom-inp" id="exampleFormControlTextarea1" placeholder='Enter Description' rows={3} name='description' value={filterInpVal?.description} onChange={handleFilterChange} />
                                                </div>
                                            </div>

                                        </div>




                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Product
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddNewProduct