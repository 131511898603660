import React from 'react'
import { Link } from 'react-router-dom'

function SettlementDetailTab() {
    return (
        <div className='p-3'>
            <div className="row">
                <h4 className='m-2'>Settlement Details</h4>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">

                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <th><span>Settlement Details:</span></th>
                                                    <td><Link to={"#"} className='text-primary' style={{ fontWeight: '600' }}>View Settlement Cycle</Link></td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <th><span>Current Balance:</span></th>
                                                    <td><b>00.00</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SettlementDetailTab