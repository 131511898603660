import React from 'react'
import OffersComp from '../../components/offersComp/OffersComp'

function OffersPage() {
    return (
        <>
            <OffersComp />
        </>
    )
}

export default OffersPage