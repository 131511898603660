import React from 'react'
import CustomersComp from '../../components/customersComp/CustomersComp'

function CustomersPage() {
    return (
        <>
            <CustomersComp />
        </>
    )
}

export default CustomersPage