import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { SiAmazondocumentdb } from "react-icons/si";

function SubscriptionTabComp() {
    const [filterInpVal, setFilterInpVal] = useState({
        customer_email: '',
        card_expiring_in: '',
        subscription_completing_in: '',
        plan_id: '',
        status: '',
        subscription_id: '',
        count: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <LiaHandsHelpingSolid /> Need Help? Take a Tour
                            </Link>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" >+ Create New Subscription</button>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card">
                        <div className='row p-2'>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <h5 style={{ fontSize: '16px' }}>Active Subscriptions: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <h5 style={{ fontSize: '16px' }}>Halted Subscriptions: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <h5 style={{ fontSize: '16px' }}>Subscriptions Completing In 7 Days: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                <div style={{ boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                    <h5 style={{ fontSize: '16px' }}>Subscriptions With Card Expiring In 7 Days: <span style={{ color: '#0d99ff' }}>00</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Customer Email</label>
                                                <input type="email" className="form-control loginCustom-inp" id="usr" placeholder='Customer Email' name='customer_email' value={filterInpVal?.customer_email} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Cards Expiring In</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='card_expiring_in' value={filterInpVal?.card_expiring_in} onChange={handleFilterChange}>
                                                    <option selected>Select</option>
                                                    <option value={'Next 7 Days'}>Next 7 Days</option>
                                                    <option value={'Next 30 Days'}>Next 30 Days</option>
                                                    <option value={'Next 60 Days'}>Next 60 Days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Subscriptions Completing In</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='subscription_completing_in' value={filterInpVal?.subscription_completing_in} onChange={handleFilterChange}>
                                                    <option selected>Select</option>
                                                    <option value={'Next 7 Days'}>Next 7 Days</option>
                                                    <option value={'Next 30 Days'}>Next 30 Days</option>
                                                    <option value={'Next 60 Days'}>Next 60 Days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Subscription Id</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Subscription iD' name='subscription_id' value={filterInpVal?.subscription_id} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Plan ID</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Plan ID' name='plan_id' value={filterInpVal?.plan_id} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Status</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Status</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Authorized'}>Authorized</option>
                                                    <option value={'Captured'}>Captured</option>
                                                    <option value={'Refunded'}>Refunded</option>
                                                    <option value={'Failed'}>Failed</option>
                                                </select>
                                            </div>
                                        </div>



                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Count</label>
                                                <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Count' name='count' value={filterInpVal?.count} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}><i className="fas fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Subscription Id</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Plan Id</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Subscription Link</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Email Address: activate to sort column ascending" style={{ width: '140.45px' }}>Customer Id</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Gender: activate to sort column ascending" style={{ width: '85.4px' }}>Next Due on</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Status</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>SB874277</span></td>
                                                    <td><span>PL123399</span></td>
                                                    <td><span className="text-primary"></span></td>
                                                    <td >
                                                        <span>CUS947272478</span>
                                                    </td>
                                                    <td >
                                                        <span>20/10/24</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>SB874277</span></td>
                                                    <td><span>PL123399</span></td>
                                                    <td><span className="text-primary"></span></td>
                                                    <td >
                                                        <span>CUS947272478</span>
                                                    </td>
                                                    <td >
                                                        <span>20/10/24</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>SB874277</span></td>
                                                    <td><span>PL123399</span></td>
                                                    <td><span className="text-primary"></span></td>
                                                    <td >
                                                        <span>CUS947272478</span>
                                                    </td>
                                                    <td >
                                                        <span>20/10/24</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionTabComp