import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { SiAmazondocumentdb } from "react-icons/si";

function PaymentPaesTab() {
    const [filterInpVal, setFilterInpVal] = useState({
        status: '',
        title: '',
        count: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <LiaHandsHelpingSolid /> Need Help? Take a Tour
                            </Link>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button">+ Create Payment Page</button>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
                                <label className="form-check-label" htmlFor="inlineRadio1">Payment Pages</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                                <label className="form-check-label" htmlFor="inlineRadio2">Storefront Pages</label>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>

                                        <div className='col-lg-4 col-md-4 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Status</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Status</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Active'}>Active</option>
                                                    <option value={'InActive'}>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Title</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Title' name='title' value={filterInpVal?.title} onChange={handleFilterChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Count</label>
                                                <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Count' name='count' value={filterInpVal?.count} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}><i className="fas fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <h4 style={{ textAlign: 'center', marginTop: '100px'  }}>There are no storefront pages yet!!
                        Start creating new pages now.</h4>
                </div>
            </div>
        </>
    )
}

export default PaymentPaesTab