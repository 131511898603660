import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { SiAmazondocumentdb } from "react-icons/si";

function Offerstab() {

    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <LiaHandsHelpingSolid /> Need Help? Take a Tour
                            </Link>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button">+ Create New Offer</button>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button">+ Create No & Low Cost EMI</button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-12">
                    <h4 style={{ textAlign: 'center', marginTop: '100px' }}>There are no offers yet!!
                    Start creating new offers now..</h4>
                </div>
            </div>
        </>
    )
}

export default Offerstab