import React from 'react'
import { Link } from 'react-router-dom';
import { GrTransaction } from "react-icons/gr";
import { LiaCheckDoubleSolid } from "react-icons/lia";
import { IoSettingsOutline } from "react-icons/io5";
import { IoSyncOutline } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { PiInvoiceLight } from "react-icons/pi";
import { TbMessageReport } from "react-icons/tb";
import { IoQrCode } from "react-icons/io5";
import { PiLinkSimpleHorizontalFill } from "react-icons/pi";
import { MdRequestPage } from "react-icons/md";
import { HiCollection } from "react-icons/hi";
import { TbRouteScan } from "react-icons/tb";
import { MdDatasetLinked } from "react-icons/md";
import { FaUserShield } from "react-icons/fa6";
import { IoGift } from "react-icons/io5";
import { SiXdadevelopers } from "react-icons/si";

function Aside() {
    return (
        <>
            <div className="deznav">
                <div className="deznav-scroll mm-active">
                    <ul className="metismenu mm-show" id="menu">
                        {/* <li className="menu-title">YOUR COMPANY</li> */}
                        <li className="mm-active">
                            <a className="" href="javascript:void(0);" aria-expanded="false">
                                <div className="menu-icon">
                                    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 7.49999L10 1.66666L17.5 7.49999V16.6667C17.5 17.1087 17.3244 17.5326 17.0118 17.8452C16.6993 18.1577 16.2754 18.3333 15.8333 18.3333H4.16667C3.72464 18.3333 3.30072 18.1577 2.98816 17.8452C2.67559 17.5326 2.5 17.1087 2.5 16.6667V7.49999Z" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.5 18.3333V10H12.5V18.3333" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <span className="nav-text">Home</span>
                            </a>
                            {/* <ul aria-expanded="false" className="mm-collapse mm-show left">
                                <li className="mm-active"><a href="index.html" className="mm-active">Dashboard Light</a></li>
                                <li><a href="index-2.html">Dashboard Dark</a></li>
                            </ul> */}
                        </li>
                        <li>
                            <Link to={'transactions'}>
                                <div className="menu-icon">
                                    <GrTransaction />
                                </div>
                                <span className="nav-text">Transactions</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'settlements'}>
                                <div className="menu-icon">
                                    <LiaCheckDoubleSolid />
                                </div>
                                <span className="nav-text">Settlements</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'reports'}>
                                <div className="menu-icon">
                                    <TbMessageReport />
                                </div>
                                <span className="nav-text">Reports</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'account-settings'}>
                                <div className="menu-icon">
                                    <IoSettingsOutline />
                                </div>
                                <span className="nav-text">Account Settings</span>
                            </Link>
                        </li>

                        <h5 className='aside-heading-text'>PAYMENTS PRODUCTS</h5>
                        <li>
                            <Link to={'subscriptions'}>
                                <div className="menu-icon">
                                    <IoSyncOutline />
                                </div>
                                <span className="nav-text">Subscriptions</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'invoices'}>
                                <div className="menu-icon">
                                    <PiInvoiceLight />
                                </div>
                                <span className="nav-text">Invoices</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'payment-buttons'}>
                                <div className="menu-icon">
                                    <MdPayment />
                                </div>
                                <span className="nav-text">Payment Button</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'payment-links'}>
                                <div className="menu-icon">
                                    <PiLinkSimpleHorizontalFill />
                                </div>
                                <span className="nav-text">Payment Links</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'QR-codes'}>
                                <div className="menu-icon">
                                    <IoQrCode />
                                </div>
                                <span className="nav-text">QR Codes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'payment-pages-page'}>
                                <div className="menu-icon">
                                    <MdRequestPage />
                                </div>
                                <span className="nav-text">Payment Pages</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={'right-comp'}>
                                <div className="menu-icon">
                                    <HiCollection />
                                </div>
                                <span className="nav-text">Smart Collect</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to={'routes'}>
                                <div className="menu-icon">
                                    <TbRouteScan />
                                </div>
                                <span className="nav-text">Route</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={'routes'}>
                                <div className="menu-icon">
                                    <TbRouteScan />
                                </div>
                                <span className="nav-text">Checkout Rewards</span>
                            </Link>
                        </li> */}
                        {/* <li>
                            <Link to={'routes'}>
                                <div className="menu-icon">
                                    <TbRouteScan />
                                </div>
                                <span className="nav-text">Affordability</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to={'me-link'}>
                                <div className="menu-icon">
                                    <MdDatasetLinked />
                                </div>
                                <span className="nav-text">AbarisPay.me Link</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'customers'}>
                                <div className="menu-icon">
                                    <FaUserShield />
                                </div>
                                <span className="nav-text">Customers</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'offers'}>
                                <div className="menu-icon">
                                    <IoGift />
                                </div>
                                <span className="nav-text">Offers</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'developers'}>
                                <div className="menu-icon">
                                    <SiXdadevelopers />
                                </div>
                                <span className="nav-text">Developers</span>
                            </Link>
                        </li>
                    </ul>
                    {/* <div className="help-desk">
                        <a href="javascript:void(0)" className="btn btn-primary">Help Desk</a>
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default Aside