import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PaymentPaesTab from './PaymentPaesTab';
import AddNewProductTab from './AddNewProductTab';

function PaymentPagesComp() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading-detail'>
                                    <div className='row p-3'>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>1. Create Payment Page</h3>
                                            <p style={{ textAlign: 'center' }}>Create your own custom pages by adding fields to collect relevant customer information.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>2. Receive Payments</h3>
                                            <p style={{ textAlign: 'center' }}>Publish your page to receive payments from your customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Payment Pages" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Payment Pages" title="Payment Pages" className='p-4'>
                                        <PaymentPaesTab />
                                    </Tab>
                                    <Tab eventKey="Products" title="Products" className='p-4'>
                                        <AddNewProductTab />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PaymentPagesComp