import React from 'react'

function FlashCheckoutTab() {
    return (
        <div className='p-3'>
            <h4>Flash Checkout</h4>
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Enable / Disable</label>
            </div>
            <div className='mt-3'>
                <h5>Securely save the card details of your customers, with AbarisPay's Flash Checkout.</h5>
            </div>
        </div>

    )
}

export default FlashCheckoutTab