import React from 'react'

function MandateSummaryPageTab() {
    return (
        <div className='p-3'>
            <h4>Show Mandate Summary Page for Cards</h4>
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Enable / Disable</label>
            </div>
            <div className='mt-3'>
                <h5>Show mandate summary page for credit and debit card payments to your users.</h5>
            </div>
        </div>

    )
}

export default MandateSummaryPageTab