import React from 'react';
import { SiAmazondocumentdb } from "react-icons/si";
import { Link } from 'react-router-dom';

function ApisKeystab() {
    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button">+ Create Website / App URL</button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Key ID</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Created At</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Expiry</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td >
                                                        <span>ABC ID</span>
                                                    </td>
                                                    <td>
                                                        <span>27/06/24 12:00 hrs</span>
                                                    </td>
                                                    <td>
                                                        <span>30/07/24 14:00 hrs</span>
                                                    </td>
                                                    <td >
                                                        <span></span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td >
                                                        <span>ABC ID</span>
                                                    </td>
                                                    <td>
                                                        <span>27/06/24 12:00 hrs</span>
                                                    </td>
                                                    <td>
                                                        <span>30/07/24 14:00 hrs</span>
                                                    </td>
                                                    <td >
                                                        <span></span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td >
                                                        <span>ABC ID</span>
                                                    </td>
                                                    <td>
                                                        <span>27/06/24 12:00 hrs</span>
                                                    </td>
                                                    <td>
                                                        <span>30/07/24 14:00 hrs</span>
                                                    </td>
                                                    <td >
                                                        <span></span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApisKeystab