import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function LoginComp() {
    const [showEmailInput, setShowEmailInput] = useState(true);
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [showEmailCodeInput, setShowEmailCodeInput] = useState(false);
    const [showMobileNoInput, setShowMobileNoInput] = useState(false);
    const [showMobileOTPInput, setShowMobileOTPInput] = useState(false);
    const [showNameInput, setShowNameInput] = useState(false);
    const [showCollectingPaymentCheckBox, setShowCollectingPaymentCheckBox] = useState(false);
    const [showLinkInput, setShowLinkInput] = useState(false);
    const [showBusinessTypeField, setShowBusinessTypeField] = useState(false);
    const [activeSpan, setActiveSpan] = useState('');
    const [showPANInput, setShowPANInput] = useState(false);
    const [showConfirmName, setShowConfirmName] = useState(false);
    const [showBrandName, setShowBrandName] = useState(false);
    const [showBusinessCategoryField, setShowBusinessCategoryField] = useState(false);
    const [showBusinessSubCategoryField, setShowBusinessSubCategoryField] = useState(false);



    const navigate = useNavigate();

    const submitEmailData = () => {
        setShowEmailInput(false)
        setShowPasswordInput(true)
    };
    const submitPasswordData = () => {
        setShowEmailInput(false)
        setShowPasswordInput(false)
        setShowEmailCodeInput(true)
    };
    const submitEmailCodeData = () => {
        setShowEmailCodeInput(false)
        setShowMobileNoInput(true)
    };
    const submitMobileNoData = () => {
        setShowMobileNoInput(false)
        setShowMobileOTPInput(true)
    };
    const submitMobileOTPData = () => {
        setShowMobileOTPInput(false)
        setShowNameInput(true)
    };
    const submitNameData = () => {
        setShowNameInput(false)
        setShowCollectingPaymentCheckBox(true)
    };
    const submitCollectingPaymentsData = () => {
        setShowCollectingPaymentCheckBox(false)
        setShowLinkInput(true)
    };
    const submitLinkData = () => {
        setShowLinkInput(false)
        setShowBusinessTypeField(true)
    };

    const handleClick = (spanName) => {
        setActiveSpan(spanName);
    };
    const submitBusinessTypeData = () => {
        setShowBusinessTypeField(false)
        setShowPANInput(true)
    };
    const submitPANData = () => {
        setShowPANInput(false)
        setShowConfirmName(true)
    };

    const handleEditPANData = () => {
        alert('edit PAN')
    };
    const submitConfirmPANName = () => {
        setShowConfirmName(false)
        setShowBrandName(true)
    };
    const submitBrandNameData = () => {
        setShowBrandName(false)
        setShowBusinessCategoryField(true)
    };
    const submitBusinessCategoryData = () => {
        setShowBusinessCategoryField(false)
        setShowBusinessSubCategoryField(true)
    };
    const submitBusinessSubCategoryData = () => {
        navigate('/admin')
        setShowBusinessSubCategoryField(false)

    }


    return (
        <>
            <div className="authincation h-100 h-100-2">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-lg-4 col-md-12 col-sm-12 mx-auto align-self-center">
                            <div className="login-form">
                                {showEmailInput && <div className="text-center">
                                    <h3 className="title">Sign In</h3>
                                    <p>Welcome to Abaris Payments</p>
                                </div>}

                                <form action="#">

                                    {showEmailInput && <div>
                                        <div className="mb-4">
                                            <label className="mb-1 text-dark">Email</label>
                                            <input type="email" placeholder='Please Enter Your Email' className="form-control form-control" defaultValue="hello@example.com" fdprocessedid="k2hurl" />
                                        </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitEmailData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showPasswordInput && <div>
                                        <div className="mb-4 position-relative">
                                            <label className="mb-1 text-dark">Create Password</label>
                                            <input type="password" placeholder='Enter Strong Password' id="dz-password" className="form-control" defaultValue={123456} fdprocessedid="c5s8m" />
                                            <span className="show-pass eye">
                                                <i className="fa fa-eye-slash" />
                                                <i className="fa fa-eye" />
                                            </span>
                                        </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitPasswordData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Create Password</button>
                                        </div>
                                    </div>}

                                    {showEmailCodeInput && <div> <div className="mb-4 position-relative">
                                        <label className="mb-1 text-dark">Enter Code Sent To Your Email</label>
                                        <input type="text" id="dz-password" placeholder='Enter code sent to your email' className="form-control" fdprocessedid="c5s8m" />
                                        <span className="show-pass eye">
                                            <i className="fa fa-eye-slash" />
                                            <i className="fa fa-eye" />
                                        </span>
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitEmailCodeData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Verify</button>
                                        </div>
                                    </div>}

                                    {showMobileNoInput && <div> <div className="mb-4 position-relative">
                                        <label className="mb-1 text-dark">Enter Your Mobile No</label>
                                        <input type="number" id="dz-password" placeholder='Enter your mobile no' className="form-control" fdprocessedid="c5s8m" />
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitMobileNoData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Send Otp</button>
                                        </div>
                                    </div>}

                                    {showMobileOTPInput && <div> <div className="mb-4 position-relative">
                                        <label className="mb-1 text-dark">Enter OTP Sent To Your Mobile No</label>
                                        <input type="text" id="dz-password" placeholder='Enter OTP' className="form-control" fdprocessedid="c5s8m" />
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitMobileOTPData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Submit Otp</button>
                                        </div>
                                    </div>}

                                    {showNameInput && <div> <div className="mb-4 position-relative">
                                        <label className="mb-1 text-dark">Enter Your Name</label>
                                        <input type="text" id="dz-password" placeholder='Enter your name' className="form-control" fdprocessedid="c5s8m" />
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitNameData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showCollectingPaymentCheckBox && <div> <div className="mb-4 position-relative">
                                        <div>
                                            <label style={{ fontSize: '18px' }} className="mb-1 text-dark mb-4">Where Do You Plan To Use Abaris Pay For Collecting Payments?</label>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                                <label style={{ fontSize: '12px' }} className="form-check-label" htmlFor="flexCheckDefault">
                                                    On My Website
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                                                <label style={{ fontSize: '12px' }} className="form-check-label" htmlFor="flexCheckChecked">
                                                    On My Android App
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                                                <label style={{ fontSize: '12px' }} className="form-check-label" htmlFor="flexCheckChecked">
                                                    On My iOS App
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                                                <label style={{ fontSize: '12px' }} className="form-check-label" htmlFor="flexCheckChecked">
                                                    On Social Media Like Facebook And Instagram
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                                                <label style={{ fontSize: '12px' }} className="form-check-label" htmlFor="flexCheckChecked">
                                                    On WhatsApp,SMS or Email
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitCollectingPaymentsData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showLinkInput && <div> <div className="mb-4 position-relative">
                                        <label className="mb-1 text-dark">Add Link</label>
                                        <input type="text" id="dz-password" placeholder='Enter link' className="form-control" fdprocessedid="c5s8m" />
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitLinkData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showBusinessTypeField && <div>
                                        <div className="text-center mb-4">
                                            <div>
                                                <h3>What's Your Business Type?</h3>
                                                <p>Pick any one that applies to your business</p>
                                            </div>
                                            <div className='mt-5 mb-5'>
                                                <h6>INDIVIDUAL BUSINESS</h6>
                                                {/* <span className='businessType-span-text'>Individual</span> */}
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'individual' ? 'active' : ''}`}
                                                    onClick={() => handleClick('individual')}
                                                >
                                                    Individual
                                                </span>
                                            </div>

                                            <div className='mt-5 mb-5'>
                                                <h6>REGISTERED BUSINESS</h6>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'partnership' ? 'active' : ''}`}
                                                    onClick={() => handleClick('partnership')}
                                                >
                                                    Partnership
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'public' ? 'active' : ''}`}
                                                    onClick={() => handleClick('public')}
                                                >
                                                    Public Limited
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'private' ? 'active' : ''}`}
                                                    onClick={() => handleClick('private')}
                                                >
                                                    Private Limited
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'prosprietorship' ? 'active' : ''}`}
                                                    onClick={() => handleClick('prosprietorship')}
                                                >
                                                    Proprietorship
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'society' ? 'active' : ''}`}
                                                    onClick={() => handleClick('society')}
                                                >
                                                    Society
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'trust' ? 'active' : ''}`}
                                                    onClick={() => handleClick('trust')}
                                                >
                                                    Trust
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'llp' ? 'active' : ''}`}
                                                    onClick={() => handleClick('llp')}
                                                >
                                                    LLP
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'huf' ? 'active' : ''}`}
                                                    onClick={() => handleClick('huf')}
                                                >
                                                    HUF
                                                </span>
                                            </div>

                                            <button type="button" onClick={submitBusinessTypeData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showPANInput && <div> <div className="mb-4 position-relative">
                                        <label className="mb-1 text-dark">Enter Your PAN Number</label><br></br>
                                        <small>We require this to verify your identity as per RBI guidelines to provide a secure Payment experience for you</small>
                                        <input type="text" id="" placeholder='Enter PAN number' className="form-control mt-2" fdprocessedid="c5s8m" />
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitPANData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showConfirmName && <div> <div className="mb-4 position-relative">
                                        <h4 className="text-dark m-0">Confirm Your Name is <span style={{ color: '#000000b0', fontWeight: 'bold', textTransform: 'uppercase' }}>"GHULAM MOINUDDIN"</span> ?</h4><br></br>
                                        <small>We've Verified the name using your PAN number.You can't make any changes to your PAN details after you confirm</small>
                                    </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '45px' }}>
                                            <button type="button" onClick={handleEditPANData} className="btn btn-primary" fdprocessedid="xptkvb">Edit PAN</button>
                                            <button type="button" onClick={submitConfirmPANName} className="btn btn-success" fdprocessedid="xptkvb">Yes, Confirm</button>
                                        </div>
                                    </div>}

                                    {showBrandName && <div> <div className="mb-4 position-relative">
                                        <label className="mb-1 text-dark">Enter Your Brand Name</label><br></br>
                                        <small>This is the business name that your customers recognize</small>
                                        <input type="text" id="dz-password" placeholder='Enter brand name' className="form-control mt-2" fdprocessedid="c5s8m" />
                                    </div>
                                        <div className="text-center mb-4">
                                            <button type="button" onClick={submitBrandNameData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showBusinessCategoryField && <div>
                                        <div className="text-center mb-4">
                                            <div>
                                                <h3>What Category Does Your Business Fall Under ?</h3>
                                            </div>

                                            <div className='mt-5 mb-5'>

                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'ecommerce' ? 'active' : ''}`}
                                                    onClick={() => handleClick('ecommerce')}
                                                >
                                                    Ecommerce
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'education' ? 'active' : ''}`}
                                                    onClick={() => handleClick('education')}
                                                >
                                                    Education
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'logistics' ? 'active' : ''}`}
                                                    onClick={() => handleClick('logistics')}
                                                >
                                                    Logistics
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'financialServices' ? 'active' : ''}`}
                                                    onClick={() => handleClick('financialServices')}
                                                >
                                                    Financial Services
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'foodBeverage' ? 'active' : ''}`}
                                                    onClick={() => handleClick('foodBeverage')}
                                                >
                                                    Food And Beverage
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'gaming' ? 'active' : ''}`}
                                                    onClick={() => handleClick('gaming')}
                                                >
                                                    gaming
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'healthcare' ? 'active' : ''}`}
                                                    onClick={() => handleClick('healthcare')}
                                                >
                                                    Healthcare
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'itSoftware' ? 'active' : ''}`}
                                                    onClick={() => handleClick('itSoftware')}
                                                >
                                                    IT And Software
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'toursTravels' ? 'active' : ''}`}
                                                    onClick={() => handleClick('toursTravels')}
                                                >
                                                    Tours And Travels
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'govtBodies' ? 'active' : ''}`}
                                                    onClick={() => handleClick('govtBodies')}
                                                >
                                                    Government Bodies
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'transportaton' ? 'active' : ''}`}
                                                    onClick={() => handleClick('transportaton')}
                                                >
                                                    Transportation
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'socialGroupPlatform' ? 'active' : ''}`}
                                                    onClick={() => handleClick('socialGroupPlatform')}
                                                >
                                                    Social Group Or Platform
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'notForProfit' ? 'active' : ''}`}
                                                    onClick={() => handleClick('notForProfit')}
                                                >
                                                    Not-For-Profit
                                                </span>
                                                <span
                                                    className={`businessType-span-text ${activeSpan === 'services' ? 'active' : ''}`}
                                                    onClick={() => handleClick('services')}
                                                >
                                                    Services
                                                </span>
                                            </div>

                                            <button type="button" onClick={submitBusinessCategoryData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                        </div>
                                    </div>}

                                    {showBusinessSubCategoryField &&
                                        <div>
                                            <div className="text-center mb-4">
                                                <div>
                                                    <h3>Select a Subcategory Under</h3>
                                                </div>

                                                <div className='mt-5 mb-5'>
                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'techSupport' ? 'active' : ''}`}
                                                        onClick={() => handleClick('techSupport')}
                                                    >
                                                        Technical Support
                                                    </span>
                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'bpo' ? 'active' : ''}`}
                                                        onClick={() => handleClick('bpo')}
                                                    >
                                                        BPO/KPO/LPO/RPO
                                                    </span>
                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'computerProgram' ? 'active' : ''}`}
                                                        onClick={() => handleClick('computerProgram')}
                                                    >
                                                        Computer Programming And Date Processing
                                                    </span>
                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'softwareService' ? 'active' : ''}`}
                                                        onClick={() => handleClick('softwareService')}
                                                    >
                                                        Software As a Service
                                                    </span>
                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'platformService' ? 'active' : ''}`}
                                                        onClick={() => handleClick('platformService')}
                                                    >
                                                        Platform As a Service
                                                    </span>
                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'techConsulting' ? 'active' : ''}`}
                                                        onClick={() => handleClick('techConsulting')}
                                                    >
                                                        Technical Consulting or Outsourcing
                                                    </span>
                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'webDesign' ? 'active' : ''}`}
                                                        onClick={() => handleClick('webDesign')}
                                                    >
                                                        Web Design, Development, Hosting
                                                    </span>

                                                    <span
                                                        className={`businessType-span-text ${activeSpan === 'infrastructureService' ? 'active' : ''}`}
                                                        onClick={() => handleClick('infrastructureService')}
                                                    >
                                                        Infrastructure As a Service
                                                    </span>

                                                </div>

                                                <button type="button" onClick={submitBusinessSubCategoryData} className="btn btn-primary btn-block" fdprocessedid="xptkvb">Continue</button>
                                            </div>
                                        </div>}








                                    {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                        <div className="mb-4">
                                            <div className="form-check custom-checkbox mb-3">
                                                <input type="checkbox" className="form-check-input" id="customCheckBox1" required />
                                                <label className="form-check-label" htmlFor="customCheckBox1">Remember my preference</label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <a href="page-forgot-password.html" className="btn-link text-primary">Forgot Password?</a>
                                        </div>
                                    </div> */}

                                    {showEmailInput && <div>
                                        <h6 className="login-title"><span>Or continue with</span></h6>
                                        <div className="mb-3">
                                            <ul className="d-flex align-self-center justify-content-center">
                                                <li><a target="_blank" href="https://www.facebook.com/" className="fab fa-facebook-f btn-facebook" /></li>
                                                <li><a target="_blank" href="https://www.google.com/" className="fab fa-google-plus-g btn-google-plus mx-2" /></li>
                                                <li><a target="_blank" href="https://www.linkedin.com/" className="fab fa-linkedin-in btn-linkedin me-2" /></li>
                                                <li><a target="_blank" href="https://twitter.com/" className="fab fa-twitter btn-twitter" /></li>
                                            </ul>
                                        </div>
                                        {/* <p className="text-center">Not registered?
                                        <a className="btn-link text-primary" href="page-register.html">Register</a>
                                    </p> */}
                                    </div>}

                                </form>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8">
                            <div className="pages-left h-100">
                                <div className="login-content">
                                    <a href="index.html"><img style={{ width: '100px' }} src="https://www.abarissoftech.com/assets/img/logo-2.png" className="mb-3 logo-dark" alt /></a>
                                    {/* <a href="index.html"><img src="images/logi-white.png" className="mb-3 logo-light" alt /></a> */}
                                    {/* <a href="index.html"><img style={{ width: '98px', height: '24px' }} src="https://www.abarissoftech.com/assets/img/logo-2.png" className="mb-3 logo-light" alt /></a> */}
                                    <p>CRM dashboard uses line charts to visualize customer-related metrics and trends over time.</p>
                                </div>
                                <div className="login-media text-center">
                                    <img src="https://w3crm.dexignzone.com/xhtml/images/login.png" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginComp