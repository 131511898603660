import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { SiAmazondocumentdb } from "react-icons/si";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

function RpoutesPaymentsTab() {
    const [filterInpVal, setFilterInpVal] = useState({
        status: '',
        payment: '',
        qr_name: '',
        customer_name: '',
        customer_email: '',
        bank_ref_no: '',
        customer_contact: '',
        notes: '',
        count: '',
    });

    const { RangePicker } = DatePicker;
    const rangePresets = [
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 14 Days',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            let dateval = `${dateStrings[0]} to ${dateStrings[1]}`
            // setPickedDateVal(dateval)
        } else {
            console.log('Clear');
        }
    };

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <LiaHandsHelpingSolid /> Need Help? Take a Tour
                            </Link>
                            <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                                <SiAmazondocumentdb /> Documentation
                            </Link>
                        </div>
                    </div>
                </div>



                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Payment ID</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Payment ID' name='payment' value={filterInpVal?.payment} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Email</label>
                                                <input type="email" className="form-control loginCustom-inp" id="usr" placeholder='Email' name='customer_email' value={filterInpVal?.customer_email} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Status</label>
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select Status</option>
                                                    <option value={'All'}>All</option>
                                                    <option value={'Active'}>Active</option>
                                                    <option value={'Closed'}>Closed</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Bank Reference Number</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Bank Reference Number' name='bank_ref_no' value={filterInpVal?.bank_ref_no} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Duration</label>
                                                <Space direction="vertical" size={12} >
                                                    <RangePicker presets={rangePresets} onChange={onRangeChange} className=' loginCustom-inp' style={{ paddingBottom: '15px' }} />
                                                </Space>
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Mobile Number</label>
                                                <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Mobile Number' name='customer_contact' value={filterInpVal?.customer_contact} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Count</label>
                                                <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Count' name='count' value={filterInpVal?.count} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                            <div className="form-group">
                                                <label className='custome-label-inp'>Notes</label>
                                                <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Notes' name='notes' value={filterInpVal?.notes} onChange={handleFilterChange} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mb-3'>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
                                            <button className="btn btn-primary custom-inp-btn rounded me-4" type="button" onClick={handleSubmitFilterData}><i className="fas fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Payment Id</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Email</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Contact</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Amount</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Status</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC ID</span></td>
                                                    <td><span>abc@gmail.com</span></td>
                                                    <td><span>9988782782</span></td>
                                                    <td >
                                                        <span>500</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC ID</span></td>
                                                    <td><span>abc@gmail.com</span></td>
                                                    <td><span>9988782782</span></td>
                                                    <td >
                                                        <span>500</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC ID</span></td>
                                                    <td><span>abc@gmail.com</span></td>
                                                    <td><span>9988782782</span></td>
                                                    <td >
                                                        <span>500</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                    <td>
                                                        <span>20/5/24 10:00 hrs</span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RpoutesPaymentsTab