import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function CustomerAddModal({ show, handleClose }) {

    const [filterInpVal, setFilterInpVal] = useState({
        email: '',
        customer_name: '',
        contact_no: '',
        gstin: '',
        description: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="active-projects style-1">
                                    <form>
                                        <div className='row p-4'>

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Customer Name</label>
                                                    <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Enter Customer Name' name='customer_name' value={filterInpVal?.customer_name} onChange={handleFilterChange} />
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Customer Email</label>
                                                    <input type="email" className="form-control loginCustom-inp" id="usr" placeholder='Enter Customer Email' name='email' value={filterInpVal?.email} onChange={handleFilterChange} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Contact Number</label>
                                                    <input type="number" className="form-control loginCustom-inp" id="usr" placeholder='Enter Contact Number' name='contact_no' value={filterInpVal?.contact_no} onChange={handleFilterChange} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>GSTIN</label>
                                                    <input type="text" className="form-control loginCustom-inp" id="usr" placeholder='Enter GSTIN' name='gstin' value={filterInpVal?.gstin} onChange={handleFilterChange} />
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Description</label>
                                                    <textarea className="form-control loginCustom-inp" placeholder='Enter Description' id="exampleFormControlTextarea1" name='description' rows={3} value={filterInpVal?.description} onChange={handleFilterChange} />
                                                </div>
                                            </div>

                                            {/* <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                <div className="form-group">
                                                    <label className='custome-label-inp'>Select Category</label>
                                                    <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='category' value={filterInpVal?.category} onChange={handleFilterChange}>
                                                        <option selected>Select Category</option>
                                                        <option value={'Active'}>ABC</option>
                                                        <option value={'InActive'}>DEF</option>
                                                    </select>
                                                </div>
                                            </div> */}

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CustomerAddModal