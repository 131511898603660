import React from 'react'
import AccountSettingComp from '../../components/accountSettingComp/AccountSettingComp'

function AccountSettingPage() {
    return (
        <>
            <AccountSettingComp />
        </>
    )
}

export default AccountSettingPage