import React, { useState } from 'react';
import { LuFileBarChart2 } from "react-icons/lu";

function ReportSchedule() {
    const [filterInpVal, setFilterInpVal] = useState({
        date: '',
    });

    const handleFilterChange = (e) => {
        const inpname = e.target.name;
        const inpval = e.target.value;
        const clonedObj = { ...filterInpVal, [inpname]: inpval };
        // clonedObj[inpname] = inpval;
        setFilterInpVal(clonedObj)
    };

    const handleSubmitFilterData = async () => {
        console.log('filterInpVal--', filterInpVal)
    };

    return (
        <>
            <div className="row mt-2 mb-2">
                <div className="col-lg-12">
                    {/* {loading && <Loadar />} */}
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="active-projects style-1">
                                <form>
                                    <div className='row p-4'>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <button className="btn btn-primary custom-inp-btn rounded float-start" type="button" onClick={handleSubmitFilterData}><LuFileBarChart2 /> Create Schedule</button>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-12'>
                                            <div className="form-group">
                                                <select className="form-select form-control loginCustom-inp" aria-label="Default select example" name='status' value={filterInpVal?.status} onChange={handleFilterChange}>
                                                    <option selected>Select</option>
                                                    <option value={'Date-Newest'}>Date-Newest</option>
                                                    <option value={'Date-Oldest'}>Date-Oldest</option>
                                                    <option value={'Status-Paused'}>Status-Paused</option>
                                                    <option value={'Status-Active'}>Status-Active</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Schedule & Report Name</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Format</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Email Address: activate to sort column ascending" style={{ width: '140.45px' }}>Email</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '160.95px' }}>Status</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Gender: activate to sort column ascending" style={{ width: '85.4px' }}>Repeat On</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Modify / Pause / Delete</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>View Activity</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC</span></td>
                                                    <td><span className="text-primary">DEF</span></td>
                                                    <td><span></span></td>
                                                    <td >
                                                        <span>abc@gmail.com</span>
                                                    </td>
                                                    <td >
                                                        <span></span>
                                                    </td>

                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC</span></td>
                                                    <td><span className="text-primary">DEF</span></td>
                                                    <td><span></span></td>
                                                    <td >
                                                        <span>abc@gmail.com</span>
                                                    </td>
                                                    <td >
                                                        <span></span>
                                                    </td>

                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC</span></td>
                                                    <td><span className="text-primary">DEF</span></td>
                                                    <td><span></span></td>
                                                    <td >
                                                        <span>abc@gmail.com</span>
                                                    </td>
                                                    <td >
                                                        <span></span>
                                                    </td>

                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportSchedule