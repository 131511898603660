import React from 'react'

function SupportTickettab() {
    return (
        <div>
            <h4 style={{ marginTop: '200px',textAlign:'center' }}>There are no queries yet!</h4>
        </div>
    )
}

export default SupportTickettab