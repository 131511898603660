import React from 'react'

function SmsTab() {
    return (
        <div className='p-3'>
            <h4>SMS Notifications</h4>
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Enable / Disable</label>
            </div>
            <div className='mt-3'>
                <h5>Receive notifications from AbarisPay via SMS on your number: <span style={{ color: '#2f8c98', fontSize: '20px', marginLeft: '15px', fontWeight: '600', }}>9876543210</span> </h5>
            </div>
        </div>

    )
}

export default SmsTab