import React from 'react';
import { CgMoreR } from "react-icons/cg";
import { Link } from 'react-router-dom';
import { SiAmazondocumentdb } from "react-icons/si";

function UpiQrTab() {
    return (
        <div className='row'>

            <div className='col-lg-12'>
                <div className='overview-heading'>
                    <div className='text-sec'>
                        <h4>UPI/QR</h4>
                        <p>GooglePay, PhonePe, BHIM & more</p>
                    </div>
                    <div className='button-sec'>
                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                            <CgMoreR /> Know More About Payment Methods
                        </Link>
                        <Link to={'#'} className='text-primary' style={{ fontSize: '16px', marginRight: '25px' }}>
                            <SiAmazondocumentdb /> Documentation
                        </Link>
                    </div>
                </div>
            </div>

            <div>
                {/* <h4 className='mt-5'>Domestic Cards</h4> */}
                <h4 className='mt-5'>Upi</h4>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className='row p-2'>
                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0 0 5px lightblue', padding: '20px 15px', borderRadius: '5px', backgroundColor: '#f0f8ff' }}>
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>UPI</span>
                                <span style={{ padding: '5px 10px', marginLeft: '', color: 'white', borderRadius: '5px', backgroundColor: 'green', fontWeight: '500' }}>Activated</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpiQrTab