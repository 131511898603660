import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import INvoicesTab from './INvoicesTab';
import ItemsTab from './ItemsTab';

function InvoicesComp() {
    return (
        <>
            <div className="container-fluid">
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='overview-heading-detail'>
                                    <div className='row p-3'>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>1. Create Invoice</h3>
                                            <p style={{ textAlign: 'center' }}>Create GST based invoices instantly and notify your customer via sms or email.</p>
                                        </div>
                                        <div className='col-lg-4 p-3'>
                                            <h3 style={{ textAlign: 'center' }}>2. Receive Payments</h3>
                                            <p style={{ textAlign: 'center' }}>Your customers can make payments directly via the invoice link.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tabs defaultActiveKey="Invoices" id="uncontrolled-tab-example" className='pt-3 dzm-tabs'>
                                    <Tab eventKey="Invoices" title="Invoices" className='p-4'>
                                        <INvoicesTab />
                                    </Tab>
                                    <Tab eventKey="Items" title="Items" className='p-4'>
                                        <ItemsTab />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default InvoicesComp