import React, { useState } from 'react';
import CustomerAddModal from './CustomerAddModal';


function CustomersTab() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const openCreateCustomerModal = () => {
        setShow(true);
    };


    return (
        <>
            <div className="row mt-2 mb-2">

                <div className="col-lg-12">
                    <div className='row p-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <button className="btn btn-primary custom-inp-btn rounded" type="button" onClick={openCreateCustomerModal}>+ Create New Customer</button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='userTablewrapper'>
                                        <table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Customer ID</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '202.575px' }}>Customer Name</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Customer Email</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Customer Contact</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '155.775px' }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC ID</span></td>
                                                    <td><span className='text-primary'>ABC Customer</span></td>
                                                    <td><span>customer@gmail.com</span></td>
                                                    <td >
                                                        <span>99899877665</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td><span>ABC ID</span></td>
                                                    <td><span className='text-primary'>ABC Customer</span></td>
                                                    <td><span>customer@gmail.com</span></td>
                                                    <td >
                                                        <span>99899877665</span>
                                                    </td>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>

                                                {/* : <div className='nodata nodata text-primary' style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>No Data</div> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        {totalCount && <Pagination onChange={onChangeVal} total={totalCount} defaultPageSize={10} />}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <CustomerAddModal show={show} handleClose={handleClose} />

           
        </>
    )
}

export default CustomersTab